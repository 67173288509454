export default function Loader() {
    return (
        <div style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: " rgba(78, 78, 78, 0.45)",
            position: "fixed",
            left: "0px",
            top: "0",
            zIndex: "999",
            display: "flex",
        }}>
            <div className="spinner-border mx-auto my-auto" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}