import React from 'react';

const FAQs = () => {
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div>
                <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                    <div style={{ width: '100%', textAlign: 'right', marginRight: '100px' }}>
                    </div>
                    <p><br /></p>
                    <h2>Frequently Asked Questions (FAQs)</h2>
                    <h3>How do I create an account?</h3>
                    <p>To create an account, follow these steps:</p>
                    <ul>
                        <li>Open the application.</li>
                        <li>Input your mobile number and select "Login".</li>
                        <li>Subsequently, an OTP will be dispatched for registration. Upon successful OTP verification, you will seamlessly transition to the personal details screen.</li>
                        <li>Populate the required fields with meticulous attention, incorporating your name, email and set mpin.</li>
                    </ul>
                    <h3>How do I add vital records?</h3>
                    <p>Adding vital records is a simple process. Just follow these steps:</p>
                    <ul>
                        <li>Navigate to the "Track Vitals" section within the app.</li>
                        <li>Choose the relevant vital sign (e.g., Blood Pressure, Fasting Blood Sugar, etc.)</li>
                        <li>Tap the "+" button to add a new record.</li>
                        <li>Provide the necessary details for the vital test.</li>
                        <li>Save the vital record.</li>
                    </ul>
                    <h3>How do I delete vital records?</h3>
                    <p>To delete vital records, follow these steps:</p>
                    <ul>
                        <li>Navigate to the "Track Vitals" section within the app.</li>
                        <li>Choose the relevant vital sign (e.g., Blood Pressure, Fasting Blood Sugar, etc.)</li>
                        <li>Swipe right on the specific vital record.</li>
                        <li>Confirm the deletion.</li>
                    </ul>
                    <h3>How do I upload prescriptions?</h3>
                    <p>Uploading prescriptions is quick and easy. Just follow these steps:</p>
                    <ul>
                        <li>Navigate to the "Prescriptions" section within the app.</li>
                        <li>Tap the "+" button to initiate the upload process.</li>
                        <li>Select or capture an image of your prescription.</li>
                        <li>Confirm the prescription details.</li>
                        <li>Save the uploaded prescription for future reference.</li>
                    </ul>
                    <h3>How do I delete prescriptions?</h3>
                    <p>Deleting prescriptions is a straightforward process. Follow these steps:</p>
                    <ul>
                        <li>Head to the "Prescriptions" section in the app.</li>
                        <li>Select the specific prescription you want to delete.</li>
                        <li>Swipe right on the prescription entry.</li>
                        <li>Confirm the deletion when prompted.</li>
                        <li>The prescription will be removed from your records.</li>
                    </ul>
                </div>
                <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                    <h3>How do I upload lab reports?</h3>
                    <p>Uploading lab reports is a seamless process. Follow these steps:</p>
                    <ul>
                        <li>Navigate to the "Lab Reports" section within the app.</li>
                        <li>Tap the "+" button to initiate the upload process.</li>
                        <li>Choose or capture an image of your lab report.</li>
                        <li>Confirm the details of the lab report.</li>
                        <li>Save the uploaded lab report for future reference.</li>
                    </ul>
                    <h3>How do I delete lab reports?</h3>
                    <p>Deleting prescriptions is a straightforward process. Follow these steps:</p>
                    <ul>
                        <li>Head to the "Lab Reports" section in the app.</li>
                        <li>Select the specific report you want to delete.</li>
                        <li>Swipe right on the report entry.</li>
                        <li>Confirm the deletion when prompted.</li>
                        <li>The report will be removed from your records.</li>
                    </ul>
                    <h3>How can I set health-related reminders?</h3>
                    <p>You can set health-related reminders as follows:</p>
                    <ul>
                        <li>Go to the "Reminders" section in the app.</li>
                        <li>Tap the "+" button to create a new reminder.</li>
                        <li>Specify the reminder details, such as the type of reminder and time.</li>
                        <li>Save the reminder.</li>
                    </ul>
                    <h3>How can I cancel health-related reminders?</h3>
                    <p>To cancel health-related reminders, follow these steps:</p>
                    <ul>
                        <li>Open the "Clock" app on your mobile device.</li>
                        <li>Locate the reminder you want to cancel in the list of set reminders.</li>
                        <li>Tap on the reminder to select it.</li>
                        <li>Look for the option to delete or cancel the reminder (this may vary depending on your device and the specific app you're using).</li>
                        <li>Confirm the cancellation when prompted.</li>
                    </ul>
                    <p>Following these steps will allow you to easily cancel any health-related reminders that you no longer need.</p>
                    <h3>How can I delete my account and data?</h3>
                    <p>To delete account and data, follow these steps:</p>
                    <ul>
                        <li>Open the application on your mobile device.</li>
                        <li>Navigate to the Settings section.</li>
                        <li>Locate the user settings option and select "Delete Account."</li>
                        <li>Follow the prompted steps to confirm the deletion of your account and data.</li>
                    </ul>
                    <p>Following these steps will allow you to easily delete account and data permanently.</p>
                    <h3>How to Change Your MPIN?</h3>
                    <p>To modify your MPIN, follow these precise steps:</p>
                    <ul>
                        <li>Launch the application.</li>
                        <li>Navigate to "App Settings" and select the option "Manage MPIN".</li>
                        <li>Input your new MPIN and confirm the change by clicking on "Submit".</li>
                    </ul>
                    <h3>How can I contact customer support?</h3>
                    <p>If you have questions or encounter issues, you can reach our customer support team by sending an email to <a href="mailto:info@healthcareclub.in">info@healthcareclub.in</a>. We're here to help!</p>
                </div>
            </div>
        </div>
    );
}

export default FAQs;
