import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function SEO({ title, description, keyword }) {
    return (
        <Helmet>
            {/* Standard metadata tags */}
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name="keywords" content={keyword}/>

            {/* End standard metadata tags */}
            
        
        </Helmet>
    );
}