import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import DetailBlog from "./DetailBlog";
import { Helmet } from "react-helmet-async";
import SEO from "../components/shared/helmet";
import Loader from "./loader";

export default function BlogRouter() {

    const url_key = useParams();
    console.log(url_key);
    const [data, setData] = useState({})
    const [blogList, setBlogList] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        console.log(url_key)
        axios.get(`${process.env.REACT_APP_BASE_URL}blog/apis/getDetailBlog/${url_key.url_key}`)
            .then(res => {
                console.log(res.data.data[0])
                setData(res.data.data[0])
                setLoading(false)
            })
    }, [])
    // useEffect(() => {
    //     setLoading(true)
    //     if (data) {
    //         axios.get(`${process.env.REACT_APP_BASE_URL}blog/apis/getRelatedBlogs?category=${data.category}`)
    //             .then(res => {
    //                 console.log(res.data.data)
    //                 setBlogList(res.data.data.filter(blog => blog.id !== data.id))
    //                 setLoading(false)
    //             })
    //     }
    // }, [data])

    useEffect(() => {
        setLoading(true)
        if (data) {
            axios.get(`${process.env.REACT_APP_BASE_URL}blog/apis/getAllBlogs`)
                .then(res => {
                    console.log(res.data.data)
                    setBlogList(res.data.data)
                    setLoading(false)
                })
        }
    },[])

    return (
        // <div className="mb-5">
        //     <img className="img-fluid w-100 mb-5" src={process.env.REACT_APP_BASE_URL + data.image} alt="" />
        //     <h1 className="mb-4">{data.title}</h1>
        //     <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
        // </div>
        <>
            <SEO title={`Healthcare Club | ${data.meta_title}`} description={data.meta_description} keyword={data.meta_keywords} />
            <div className="container-fluid py-6 px-5">
                <div className="row g-5">
                    <div className="col-lg-8">
                        <DetailBlog data={data} />
                    </div>
                    <div className="col-lg-4">
                        <div className="mb-5 d-flex flex-column w-100 mt-8">
                            <h2 className="mb-4">Recent Post</h2>
                            {
                                blogList.length > 0 &&
                                blogList.slice(0, 3).map(blog =>
                                    <Link to={`/blogdetail${blog.url_key}`} className="d-flex w-100 mb-3" style={{ cursor: "pointer" }}>
                                        <div className="d-flex w-100 mb-3" style={{ cursor: "pointer" }}>
                                            <img className="img-fluid" src={process.env.REACT_APP_BASE_URL + 'blogImage/' + blog.image} style={{ width: "100px", height: "100px", objectFit: "cover" }} alt="" />
                                            <div className="h5 w-100 px-3 d-flex align-items-center bg-secondary px-3 mb-0">{blog.title.length > 30 ? blog.title.slice(0, 20) + '...' : blog.title}
                                            </div>
                                        </div>
                                    </Link>
                                )}
                            {/* {
                                blogList.length > 0 ?
                                    blogList.slice(0, 3).map(blog =>
                                        <Link to={`/blogdetail${blog.url_key}`} className="d-flex w-100 mb-3" style={{ cursor: "pointer" }}>
                                            <img className="img-fluid" src={process.env.REACT_APP_BASE_URL + 'blogImage/' + blog.image} style={{ width: "100px", height: "100px", objectFit: "cover" }} alt="" />
                                            <div className="h5 w-100 px-3 d-flex align-items-center bg-secondary px-3 mb-0">{blog.title.length > 30 ? blog.title.slice(0, 20) + '...' : blog.title}
                                            </div>
                                        </Link>
                                    )
                                    :
                                    <div className="text-danger w-100 ">No Recent Post Found</div>
                            } */}
                        </div>
                    </div>
                </div>
                {loading && <Loader />}
            </div>
        </>
    )
}