import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../shared/Sidebar';
import Header from '../shared/Header';
import SEO from '../shared/helmet';

const Layout = () => {
  return (
    <>
      {/* <SEO title="Healthcare Club | Admin Dashboard" description="Admin Dashboard"/> */}
      <div className='d-flex flex-row w-100 overflow-x-hidden text-primary' style={{ height: "100vh", }}>
        <Sidebar />
        <div className='flex-grow-1 h-100'>
          {/* <Header /> */}
          <div>{<Outlet />}</div>
        </div>
      </div>
    </>

  )
}

export default Layout