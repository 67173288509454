// This will prevent non-authenticated users from accessing this route
import { useSelector } from "react-redux"
import { Navigate, useLocation, Outlet } from "react-router-dom"

function PrivateRoute({ children }) {
  const { token } = useSelector((state) => state.auth)
  const location = useLocation();

  return(
    token !== null
      ? <Outlet />
      : <Navigate to="/login" state={{ from : location }} replace />
  ) 
}

export default PrivateRoute
