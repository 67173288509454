import React, {useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom'

const Footer = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
  
    return (
        <div>
            <div className="container-fluid bg-dark text-secondary p-5">
                <div className="row g-5">
                    <div className="col-lg-4 col-md-6">
                        <h3 className="text-white mb-4">Quick Links</h3>
                        <div className="d-flex flex-column justify-content-start">
                            <Link className="text-secondary mb-2" to="/"><i className="bi bi-arrow-right text-primary me-2"></i>Home</Link>
                            <Link className="text-secondary mb-2" to="/about"><i className="bi bi-arrow-right text-primary me-2"></i>About Us</Link>
                            <Link className="text-secondary mb-2" to="/blog"><i className="bi bi-arrow-right text-primary me-2"></i>Latest Blogs</Link>
                            <Link className="text-secondary" to="/contact"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <h3 className="text-white mb-4">Get In Touch</h3>
                        <div className='d-inline-flex'>
                            <i className="bi bi-geo-alt text-primary me-2"></i>
                            <div>
                            <p className="mb-0">B1/E13, Block E, Mohan Cooperative Industrial Estate, </p>
                            <p className="mb-2">Badarpur, New Delhi - 110044</p>
                            </div>
                            
                        </div>
                        <a href="mailto:info@healthcareclub.in" className='text-white'>
                            <p className="mb-2">
                                <i className="bi bi-envelope-open text-primary me-2"></i>
                                info@healthcareclub.in
                            </p>
                        </a>
                        <p className="mb-0"><i className="bi bi-telephone text-primary me-2"></i>011 44756172</p>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <h3 className="text-white mb-4">Follow Us</h3>
                        <div className="d-flex">
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" to="#"><i className="fab fa-twitter fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" to="#"><i className="fab fa-facebook-f fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" to="#"><i className="fab fa-linkedin-in fw-normal"></i></Link>
                            <Link className="btn btn-lg btn-primary btn-lg-square rounded-circle" to="#"><i className="fab fa-instagram fw-normal"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-dark text-secondary text-center border-top py-4 px-5" style={{ borderColor: "rgba(256, 256, 256, .1) !important" }}>
                <p className="m-0">&copy; 2024 <Link className="text-secondary border-bottom" to="/">Healthcare Club</Link>. All Rights Reserved.</p>
            </div>
        </div>
    )
}

export default Footer