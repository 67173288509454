import React from "react";
const Terms = () =>{
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

        <div>


           <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                <div>
                <div style={{ width: '100%', textAlign: 'right', marginRight: '100px' }}>
                    </div>
                    <p> <br /></p>
                    <p><b>TERMS AND CONDITIONS</b> <br /> <br /><b>Last updated</b> <b>October 31, 2023 </b> <br />
                        <br />
                    </p>
                    <p><b>AGREEMENT TO OUR LEGAL TERMS <br /></b> <br /> <br /></p>
                    <p>We are Healthcare Club ('<b>Company</b>', '<b>we</b>', '<b>us</b>', or '<b>our</b>'). <br /> <br />
                        <br />
                    </p>
                    <p>We operate the mobile application Healthcare Club (the '<b>App</b>'), as well as any other related
                        products and
                        services <br />that refer or link to these legal terms (the '<b>Legal Terms</b>') (collectively,
                        the
                        '<b>Services</b>'). <br /> <br /> <br />You can contact us by email at <a
                            style={{ textDecoration: 'none' }} href="mailto:info@healthcareclub.in">info@healthcareclub.in</a> <br />
                        <br />
                        <br />These Legal Terms constitute a legally binding agreement made between you, whether
                        personally or
                        on <br />behalf of an entity ('<b>you</b>'), and Healthcare Club concerning your access to and use of
                        the
                        Services. You agree that by <br />accessing the Services, you have read, understood, and agreed
                        to be
                        bound by all of these Legal Terms. IF <br />YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN
                        YOU ARE
                        EXPRESSLY <br />PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                        <br />
                        <br /> <br />We will provide you with prior notice of any scheduled changes to the Services you
                        are
                        using. The modified <br />Legal Terms will become effective upon posting or notifying you by
                        <a style={{ textDecoration: 'none' }} href="mailto:info@healthcareclub.in">info@healthcareclub.in</a>, as stated in
                        the email <br />message. By
                        continuing
                        to use
                        the Services after the
                        effective date of any changes, you agree to be bound <br />by the modified terms. <br />
                    </p>
                    <p> <br /> <br />
                    The Services are intended for users who are at least 13 years of age. All users who are minors in
                    the
                    <br />jurisdiction in which they reside (generally under the age of 18) must have the permission of,
                    and
                    be directly <br />supervised by, their parent or guardian to use the Services. If you are a minor,
                    you
                    must have your parent <br />or guardian read and agree to these Legal Terms prior to you using the
                    Services. <br /> <br /> <br />We recommend that you print a copy of these Legal Terms for your
                    records.
                    <br /> <br /> <br /> <br /> <br /><b> </b> <br /><b>TABLE OF CONTENTS <br /></b> <br /> <br />1. OUR
                    SERVICES <br />2. INTELLECTUAL PROPERTY RIGHTS <br />3. USER REPRESENTATIONS <br />4. USER
                    REGISTRATION
                    <br />5. PROHIBITED ACTIVITIES <br/>6. USER GENERATED CONTRIBUTIONS <br />7. CONTRIBUTION LICENCE
                    <br />8.
                    GUIDELINES FOR REVIEWS <br />9.
                    MOBILE APPLICATION LICENCE <br />10. SOCIAL MEDIA <br />11. SERVICES MANAGEMENT <br />12. PRIVACY
                    POLICY
                    <br />13. TERM AND TERMINATION <br />14. MODIFICATIONS AND INTERRUPTIONS <br />15. GOVERNING LAW
                    <br />16. DISPUTE RESOLUTION <br />17. CORRECTIONS <br />18. DISCLAIMER <br />19. LIMITATIONS OF
                    LIABILITY <br />20. INDEMNIFICATION <br />21. USER DATA <br />22. ELECTRONIC COMMUNICATIONS,
                    TRANSACTIONS, AND SIGNATURES <br />23. MISCELLANEOUS <br />24. CONTACT US <br /> <br /> <br />
                    <br />
                    <br />
                    </p>

                </div>
            </div>
           <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                <div>

                    <p><b>1. OUR SERVICES <br /></b> <br /> <br />The information provided when using the services is
                        not
                        intended for distribution to or use by any person or <br />entity in any jurisdiction or country
                        where
                        such distribution or use would be contrary to law, regulation, or <br />which would subject us
                        to any
                        registration requirement within such a jurisdiction or country. <br />Accordingly, Those who
                        choose to
                        access the services from other locations do so on their own initiative and <br />are solely
                        responsible
                        for compliance with local laws, if and to the extent local laws are applicable. <br /> <br />
                    </p>
                    <p><b>2. INTELLECTUAL PROPERTY RIGHTS <br /></b> <br /> <br /></p>
                    <p><b>Our intellectual property <br /></b> <br /> <br />We are the owner or the licensee of all
                        intellectual
                        property rights in our services, including all source code, <br />databases, functionality,
                        software,
                        application designs, audio, video, text, photographs, and graphics in the <br />services
                        (collectively,
                        the 'Content'), as well as the trademarks, service marks, and logos contained therein <br />(the
                        'Marks'). <br /> <br /> <br />Our content and marks are protected by copyright and trademark
                        laws (and
                        various other intellectual <br />property rights and unfair competition laws) and treaties in
                        the United
                        States and around the world. <br /> <br /> <br />The content and marks are provided in or
                        through the
                        services 'AS IS' for your personal, non-commercial, or <br />internal business purposes only.
                        <br />
                        <br /><b>Your use of our Services <br /></b> <br />
                    </p>

                </div>
            </div>
           <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                <div>
                    <p>Subject to your compliance with these legal terms, including the 'PROHIBITED ACTIVITIES' section
                        below,
                        <br />we grant you a non-exclusive, non-transferable, revocable license to: <br />
                    </p>
                    <p>&#8226; access the services; and <br />&#8226; Download or print a copy of any portion of the
                        content to
                        which you have properly gained access. <br /></p>
                    <p>solely for your personal, non-commercial, or internal business purposes. <br /> <br /> <br />
                        <br />Except as set out in this section or elsewhere in our Legal Terms, no part of the Services
                        and no
                        Content or <br />Marks may be copied, reproduced, aggregated, republished, uploaded, posted,
                        publicly
                        displayed, <br />encoded, translated, transmitted, distributed, sold, licensed, or otherwise
                        exploited
                        for any commercial <br />purpose whatsoever, without our express prior written permission.
                        <br /> <br />
                        <br />If you wish to make any use of the Services, Content, or Marks other than as set out in
                        this
                        section or <br />elsewhere in our Legal Terms, please address your request to: info@Healthcare Club.in.
                        If we
                        ever grant you the <br />permission to post, reproduce, or publicly display any part of our
                        Services or
                        Content, you must identify us <br />as the owners or licensors of the Services, Content, or
                        Marks and
                        ensure that any copyright or proprietary <br />notice appears or is visible on posting,
                        reproducing, or
                        displaying our Content. <br />
                    </p>
                    <p> <br /> <br /></p>
                    <p>We reserve all rights not expressly granted to you in and to the services, content, and marks.
                        <br />
                        <br /> <br />Any breach of these intellectual property rights will constitute a material breach
                        of our
                        legal terms, and your <br />right to use our services will terminate immediately. <br /> <br />
                        <br /><b>Your submissions and contributions <br /></b> <br /> <br />Please review this section
                        and the
                        'PROHIBITED ACTIVITIES' section carefully prior to using our services to <br />understand the
                        (a) rights
                        you give us and (b) obligations you have when you post or upload any content <br />through the
                        services.
                        <br /> <br /> <br /><b>Submissions:</b> By directly sending us any question, comment,
                        suggestion, idea,
                        feedback, or other <br />information about the Services ('Submissions'), you agree to assign to
                        us all
                        intellectual property rights in such <br />Submission. You agree that we shall own this
                        submission and
                        be entitled to its unrestricted use and <br />dissemination for any lawful purpose, commercial
                        or
                        otherwise, without acknowledgment or compensation to <br />you. <br /> <br />
                        <br /><b>Contributions:</b> The Services may invite you to chat, contribute to, or participate
                        in blogs,
                        message boards, <br />online forums, and other functionality during which you may create,
                        submit, post,
                        display, transmit, publish, <br />distribute, or broadcast content and materials to us or
                        through the
                        Services, including but not limited to text, <br />writings, video, audio, photographs, music,
                        graphics,
                        comments, reviews, rating suggestions, personal <br />information, or other material
                        ('Contributions').
                        Any submission that is publicly posted will also be treated as <br />a contribution. <br />
                        <br />
                        <br />You understand that contributions may be viewable by other users of the services. <br />
                        <br />
                        <br /> <br /><b>When you post contributions, you grant us a license (including use of your name,
                            trademarks, and <br />logos): </b>By posting any Contributions, you grant us an
                        unrestricted,
                        unlimited, irrevocable, perpetual,
                    </p>

                </div>
            </div>
           <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                <div>
                    <p>nonexclusive, transferable, royalty-free, fully-paid, worldwide right and license to: use, copy,
                        reproduce, <br />distribute, sell, resell, publish, broadcast, retitle, store, publicly perform,
                        publicly display, reformat, translate, <br />excerpt (in whole or in part), and exploit your
                        Contributions (including, without limitation, your image, name, <br />and voice) for any
                        purpose,
                        commercial, advertising, or otherwise, to prepare derivative works of, or <br />incorporate into
                        other
                        works, your Contributions, and to sublicense the licenses granted in this section. Our <br />use
                        and
                        distribution may occur in any media format and through any media channels. <br /> <br />
                        <br />This
                        license includes our use of your name, company name, and franchise name, as applicable, and any
                        of
                        <br />the trademarks, service marks, trade names, logos, and personal and commercial images you
                        provide.
                        <br /> <br /> <br /> <br /><b>You are responsible for what you post or upload. </b>By sending us
                        Submissions and/or posting <br />Contributions through any part of the Services or making
                        Contributions
                        accessible through the Services by <br />linking your account through the Services to any of
                        your social
                        networking accounts, you: <br />
                    </p>
                    <p>&#8226; confirm that you have read and agree with our 'PROHIBITED ACTIVITIES' and will not post,
                        send,
                        <br />publish, upload, or transmit through the services any submission or any contribution that
                        is
                        illegal, <br />harassing, hateful, harmful, defamatory, obscene, bullying, abusive,
                        discriminatory,
                        threatening to any <br />person or group, sexually explicit, false, inaccurate, deceitful, or
                        misleading; <br />
                    </p>
                    <p>&#8226; to the extent permissible by applicable law, waive any and all moral rights to any such
                        submission and/or <br />contribution; <br /></p>
                    <p>&#8226; warrant that any such Submission and/or Contributions are original to you or that you
                        have the
                        necessary <br />rights and licenses to submit such Submissions and/or Contributions and that you
                        have
                        full authority to <br />grant us the above-mentioned rights in relation to your Submissions
                        and/or
                        Contributions; and <br /></p>
                    <p>&#8226; warrant and represent that your submissions and/or contributions do not constitute
                        confidential
                        <br />information. <br />
                    </p>
                    <p>You are solely responsible for your submissions and/or contributions, and you expressly agree to
                        reimburse <br />us for any and all losses that we may suffer because of your breach of (a) this
                        section,
                        (b) any third party&#8217;s <br />intellectual property rights, or (c) applicable law. <br />
                        <br />
                        <br /> <br /><b>We may remove or edit your content.</b> Although we have no obligation to
                        monitor any
                        contributions, we <br />shall have the right to remove or edit any contributions at any time
                        without
                        notice if, in our reasonable <br />opinion, we consider such contributions harmful or in breach
                        of these
                        legal terms. If we remove or edit any <br />such contributions, we may also suspend or disable
                        your
                        account and report you to the authorities. <br /> <br /> <br /> <br />
                    </p>
                    <p><b>3. USER REPRESENTATIONS <br /></b> <br /> <br /></p>
                    <p>By using the Services, you represent and warrant that: (1) all registration information you
                        submit will
                        be true, <br />accurate, current, and complete; (2) you will maintain the accuracy of such
                        information
                        and promptly update <br />such registration information as necessary; (3) you have the legal
                        capacity
                        and you agree to comply with <br />these Legal Terms; (4) you are not under the age of 13; (5)
                        you are
                        not a minor in the jurisdiction in which <br />you reside, or if a minor, you have received
                        parental
                        permission to use the Services; (6) you will not access <br />the Services through automated or
                        non-human means, whether through a bot, script or otherwise; (7) you <br />will not use the
                        Services for
                        any illegal or unauthorised purpose; and (8) your use of the Services will not <br />violate any
                        applicable law or regulation. <br /> <br /> <br />If you provide any information that is untrue,
                        inaccurate, not current, or incomplete, we have the right to <br />suspend or terminate your
                        account and
                        refuse any and all current or future use of the services (or any <br />portion thereof). <br />
                    </p>

                </div>
            </div>
           <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                <div>
                    <p> <br /> <br /> <br /></p>
                    <p><b>4. USER REGISTRATION <br /></b> <br /> <br />You may be required to register to use the
                        Services. You
                        agree to keep your password confidential and will <br />be responsible for all use of your
                        account and
                        password. We reserve the right to remove, reclaim, or <br />change a username you select if we
                        determine, in our sole discretion, that such username is inappropriate, <br />obscene, or
                        otherwise
                        objectionable. <br /> <br /> <br /></p>
                    <p><b>5. PROHIBITED ACTIVITIES <br /></b> <br /> <br />You may not access or use the Services for
                        any
                        purpose other than that for which we make the Services <br />available. The Services may not be
                        used in
                        connection with any commercial endeavours except those that <br />are specifically endorsed or
                        approved
                        by us. <br /> <br /> <br />As a user of the Services, you agree not to: <br /></p>
                    <p>&#8226; Systematically retrieve data or other content from the services to create or compile,
                        directly or
                        indirectly, <br />a collection, compilation, database, or directory without written permission
                        from us.
                        <br />
                    </p>
                    <p>&#8226; Trick, defraud, or mislead us and other users, especially in any attempt to learn
                        sensitive
                        account <br />information such as user passwords. <br /></p>
                    <p>&#8226; Circumvent, disable, or otherwise interfere with security-related features of the
                        services,
                        including features <br />that prevent or restrict the use or copying of any content or enforce
                        limitations on the use of the services <br />and/or the content contained therein. <br /></p>
                    <p>&#8226; Disparage, tarnish, or otherwise harm, in our opinion, us and/or the services
                        <br />&#8226; Use
                        any information obtained from the services in order to harass, abuse, or harm another person.
                        <br />&#8226; Make improper use of our support services or submit false reports of abuse or
                        misconduct.
                        <br />&#8226; Use the services in a manner inconsistent with any applicable laws or regulations.
                        <br />&#8226; Engage in unauthorized framing of or linking to the services. <br />&#8226; Upload
                        or
                        transmit (or attempt to upload or transmit) viruses, Trojan horses, or other material, including
                        <br />
                    </p>
                    <p>excessive use of capital letters and spamming (continuous posting of repetitive text), that
                        interferes
                        with <br />any party&#8217;s uninterrupted use and enjoyment of the Services or modifies,
                        impairs,
                        disrupts, alters, or <br />interferes with the use, features, functions, operation, or
                        maintenance of
                        the services. <br /></p>
                    <p>&#8226; Engage in any automated use of the system, such as using scripts to send comments or
                        messages,
                        <br />or using any data mining, robots, or similar data gathering and extraction tools. <br />
                    </p>
                    <p>&#8226; Delete the copyright or other proprietary rights notice from any content. <br />&#8226;
                        Attempt
                        to impersonate another user or person, or use the username of another user. <br />&#8226; Upload
                        or
                        transmit (or attempt to upload or transmit) any material that acts as a passive or active <br />
                    </p>
                    <p>information collection or transmission mechanism, including, without limitation, clear graphics
                        <br />interchange formats ('gifs'), 1&#215;1 pixels, web bugs, cookies, or other similar devices
                        (sometimes <br />referred to as'spyware' or 'passive collection mechanisms' or 'pcms'). <br />
                    </p>
                    <p>&#8226; Interfere with, disrupt, or create an undue burden on the services or the networks or
                        services
                        <br />connected to them. <br />
                    </p>
                    <p>&#8226; Harass, annoy, intimidate, or threaten any of our employees or agents engaged in
                        providing any
                        <br />portion of the services to you. <br />
                    </p>
                    <p>&#8226; Attempt to bypass any measures of the services designed to prevent or restrict access to
                        the
                        <br />services or any portion of the services. <br />
                    </p>
                    <p>&#8226; Copy or adapt the services' software, including but not limited to Flash, PHP, HTML,
                        JavaScript,
                        or <br />other code. <br /></p>
                    <p>&#8226; Except as permitted by applicable law, decipher, decompile, disassemble, or reverse
                        engineer any
                        of <br />the software comprising or in any way making up a part of the services. </p>

                </div>
            </div>
           <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                <div>
                    <p>&#8226; Except as may be the result of standard search engine or Internet browser usage, use,
                        launch,
                        <br />develop, or distribute any automated system, including without limitation, any spider,
                        robot,
                        cheat <br />utility, scraper, or offline reader that accesses the Services, or use or launch any
                        unauthorized script <br />or other software. <br />
                    </p>
                    <p>&#8226; Use a buying agent or purchasing agent to make purchases for the services. <br />&#8226;
                        Make any
                        unauthorized use of the services, including collecting usernames and/or email addresses <br />
                    </p>
                    <p>of users by electronic or other means for the purpose of sending unsolicited email or creating
                        user
                        <br />accounts by automated means or under false pretenses. <br />
                    </p>
                    <p>&#8226; Use the services as part of any effort to compete with us or otherwise use the services
                        and/or
                        the <br />content for any revenue-generating endeavor or commercial enterprise. <br /></p>
                    <p> <br /> <br /></p>
                    <p>1. USER GENERATED CONTRIBUTIONS <br /> <br /> <br />The Services may invite you to chat,
                        contribute to,
                        or participate in blogs, message boards, online forums, <br />and other functionality, and may
                        provide
                        you with the opportunity to create, submit, post, display, transmit, <br />perform, publish,
                        distribute,
                        or broadcast content and materials to us or on the Services, including but not <br />limited to
                        text,
                        writings, video, audio, photographs, graphics, comments, suggestions, or personal
                        <br />information or
                        other material (collectively, 'Contributions'). Contributions may be viewable by other users of
                        <br />the services and through third-party websites. As such, any contributions you transmit may
                        be
                        treated as <br />non-confidential and non-proprietary. When you create or make available any
                        contributions, you thereby <br />represent and warrant that: <br /> <br />
                    </p>
                    <p>&#8226; The creation, distribution, transmission, public display, or performance, and the
                        accessing,
                        downloading, <br />or copying of your contributions do not and will not infringe the proprietary
                        rights,
                        including but not limited <br />to the copyright, patent, trademark, trade secret, or moral
                        rights of
                        any third party. <br /></p>
                    <p>&#8226; You are the creator and owner of or have the necessary licenses, rights, consents,
                        releases, and
                        <br />permissions to use and to authorize us, the Services, and other users of the Services to
                        use your
                        <br />contributions in any manner contemplated by the Services and these Legal Terms. <br />
                    </p>
                    <p>&#8226; You have the written consent, release, and/or permission of each and every identifiable
                        individual person <br />in your Contributions to use the name or likeness of each and every such
                        identifiable individual person to <br />enable inclusion and use of your Contributions in any
                        manner
                        contemplated by the Services and these <br />Legal Terms. <br /></p>
                    <p>&#8226; Your contributions are not false, inaccurate, or misleading. <br /> <br /></p>
                    <p>&#8226; Your contributions are not unsolicited or unauthorized advertising, promotional
                        materials,
                        pyramid <br />schemes, chain letters, spam, mass mailings, or other forms of solicitation.
                        <br />
                    </p>
                    <p>&#8226; Your contributions are not obscene, lewd, lascivious, filthy, violent, harassing,
                        libellous,
                        slanderous, <br />or otherwise objectionable (as determined by us). <br /></p>
                    <p>&#8226; Your contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.
                        <br />&#8226;
                        Your contributions are not used to harass or threaten (in the legal sense of those terms) any
                        other
                        <br />
                    </p>
                    <p>person or to promote violence against a specific person or class of people. <br />&#8226; Your
                        contributions do not violate any applicable law, regulation, or rule. <br />&#8226; Your
                        contributions
                        do not violate the privacy or publicity rights of any third party. <br />&#8226; Your
                        contributions do
                        not violate any applicable law concerning child pornography or are otherwise <br /></p>
                    <p>intended to protect the health or well-being of minors. <br />&#8226; Your contributions do not
                        include
                        any offensive comments that are connected to race, national origin, <br /></p>
                    <p>gender, sexual preference, or physical handicap. <br />&#8226; Your contributions do not
                        otherwise
                        violate, or link to material that violates, any provision of these <br /></p>
                    <p>legal terms or any applicable law or regulation. <br /> <br />Any use of the Services in
                        violation of the
                        foregoing violates these Legal Terms and may result in, among <br />other things, termination or
                        suspension of your rights to use the Services. <br /> <br /> </p>

                </div>
            </div>
           <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                <div>
                    <p><b>6. CONTRIBUTION LICENCE <br /></b> <br /> <br />By posting your Contributions to any part of
                        the
                        Services or making Contributions accessible to the Services <br />by linking your account from
                        the
                        Services to any of your social networking accounts, you automatically grant, <br />and you
                        represent and
                        warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable,
                        <br />perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and
                        licence to
                        host, use, <br />copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive,
                        store,
                        cache, publicly perform, <br />publicly display, reformat, translate, transmit, excerpt (in
                        whole or in
                        part), and distribute such Contributions <br />(including, without limitation, your image and
                        voice) for
                        any purpose, commercial, advertising, or otherwise, <br />and to prepare derivative works of, or
                        incorporate into other works, such Contributions, and grant and <br />authorise sublicences of
                        the
                        foregoing. The use and distribution may occur in any media formats and <br />through any media
                        channels.
                        <br /> <br /> <br />This licence will apply to any form, media, or technology now known or
                        hereafter
                        developed, and includes <br />our use of your name, company name, and franchise name, as
                        applicable, and
                        any of the trademarks, <br />service marks, trade names, logos, and personal and commercial
                        images you
                        provide. You waive all moral <br />rights in your Contributions, and you warrant that moral
                        rights have
                        not otherwise been asserted in your <br />Contributions. <br /> <br /> <br />We do not assert
                        any
                        ownership over your contributions. You retain full ownership of all of your <br />Contributions
                        and any
                        intellectual property rights or other proprietary rights associated with your
                        <br />Contributions We are
                        not liable for any statements or representations in your contributions provided by you <br />in
                        any area
                        of the services. You are solely responsible for your contributions to the services, and you
                        <br />expressly agree to exonerate us from any and all responsibility and to refrain from any
                        legal
                        action against <br />us regarding your contributions. <br /> <br /> <br />We have the right, in
                        our sole
                        and absolute discretion, (1) to edit, redact, or otherwise change any <br />Contributions; (2)
                        to
                        re-categorise any Contributions to place them in more appropriate locations on the
                        <br />Services; and
                        (3) to pre-screen or delete any Contributions at any time and for any reason, without notice.
                        <br />We
                        have no obligation to monitor your Contributions. <br /> <br /> <br /> <br />
                    </p>
                    <p><b>7. GUIDELINES FOR REVIEWS <br /></b> <br /> <br />We may provide you areas on the Services to
                        leave
                        reviews or ratings. When posting a review, you must <br />comply with the following criteria:
                        (1) you
                        should have firsthand experience with the person/entity being <br />reviewed; (2) your reviews
                        should
                        not contain offensive profanity, or abusive, racist, offensive, or hateful <br />language; (3)
                        your
                        reviews should not contain discriminatory references based on religion, race, gender,
                        <br />national
                        origin, age, marital status, sexual orientation, or disability; (4) your reviews should not
                        contain
                        <br />references to illegal activity; (5) you should not be affiliated with competitors if
                        posting
                        negative reviews; (6) <br />you should not make any conclusions as to the legality of conduct;
                        (7) you
                        may not post any false or <br />misleading statements; and (8) you may not organise a campaign
                        encouraging others to post reviews, <br />whether positive or negative. <br /> <br /> <br />
                        <br />We
                        may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation
                        to screen
                        <br />reviews or to delete reviews, even if anyone considers reviews objectionable or
                        inaccurate.
                        Reviews are not <br />endorsed by us, and do not necessarily represent our opinions or the views
                        of any
                        of our affiliates or <br />partners. We do not assume liability for any review or for any
                        claims,
                        liabilities, or losses resulting from any
                    </p>

                </div>
            </div>
           <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                <div>
                    <p>review. By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide,
                        royalty-free,
                        fully <br />paid, assignable, and sublicensable right and licence to reproduce, modify,
                        translate,
                        transmit by any <br />means, display, perform, and/or distribute all content relating to review.
                        <br />
                        <br /> <br />
                    </p>
                    <p><b>8. MOBILE APPLICATION LICENCE <br /></b> <br /> <br /><b>Use Licence <br /></b> <br />
                        <br />If you
                        access the Services via the App, then we grant you a revocable, non-exclusive, non-transferable,
                        <br />limited right to install and use the App on wireless electronic devices owned or
                        controlled by you
                        and to <br />access and use the App on such devices strictly in accordance with the terms and
                        conditions
                        of this mobile <br />application license contained in these Legal Terms. You shall not: (1)
                        except as
                        permitted by applicable law, <br />decompile, reverse engineer, disassemble, attempt to derive
                        the
                        source code of, or decrypt the app; (2) <br />make any modification, adaptation, improvement,
                        enhancement, translation, or derivative work from the <br />App; (3) violate any applicable
                        laws, rules,
                        or regulations in connection with your access or use of the App; <br />(4) remove, alter, or
                        obscure any
                        proprietary notice (including any notice of copyright or trademark) posted <br />by us or the
                        licensors
                        of the App; (5) use the App for any revenue-generating endeavour, commercial <br />enterprise,
                        or other
                        purpose for which it is not designed or intended; (6) make the App available over a
                        <br />network or
                        other environment permitting access or use by multiple devices or users at the same time; (7)
                        <br />use
                        the App for creating a product, service, or software that is, directly or indirectly,
                        competitive with
                        or in <br />any way a substitute for the App; (8) use the App to send automated queries to any
                        website
                        or to send any <br />unsolicited commercial email; or (9) use any proprietary information or any
                        of our
                        interfaces or our other <br />intellectual property in the design, development, manufacture,
                        licensing,
                        or distribution of any applications, <br />accessories, or devices for use with the App. <br />
                        <br />
                        <br /> <br /><b>Apple and Android Devices <br /></b> <br /> <br />The following terms apply when
                        you use
                        the app obtained from either the Apple Store or Google Play (each <br />an 'App Distributor') to
                        access
                        the services: (1) The license granted to you for our app is limited to a <br />nontransferable
                        license
                        to use the application on a device that utilizes the Apple iOS or Android operating
                        <br />systems, as
                        applicable, and in accordance with the usage rules set forth in the applicable app
                        distributor&#8217;s
                        <br />terms of service; (2) We are responsible for providing any maintenance and support
                        services with
                        respect to <br />the App as specified in the terms and conditions of this mobile application
                        license
                        contained in these Legal <br />Terms or as otherwise required under applicable law, and you
                        acknowledge
                        that each App Distributor has no <br />obligation whatsoever to furnish any maintenance and
                        support
                        services with respect to the App; (3) in the <br />event of any failure of the App to conform to
                        any
                        applicable warranty, you may notify the applicable App <br />Distributor, and the App
                        Distributor, in
                        accordance with its terms and policies, may refund the purchase <br />price, if any, paid for
                        the App,
                        and to the maximum extent permitted by applicable law, the App Distributor <br />will have no
                        other
                        warranty obligation whatsoever with respect to the App; (4) you represent and warrant <br />that
                        (i) you
                        are not located in a country that is subject to a US government embargo, or that has been
                        <br />designated by the US government as a 'terrorist supporting' country and (ii) you are not
                        listed on
                        any US <br />government list of prohibited or restricted parties; (5) you must comply with
                        applicable
                        third-party terms of <br />agreement when using the App, e.g. if you have a VoIP application,
                        then you
                        must not be in violation of their <br />wireless data service agreement when using the App; and
                        (6) you
                        acknowledge and agree that the App <br />Distributors are third-party beneficiaries of the terms
                        and
                        conditions in this mobile application licence <br />contained in these Legal Terms, and that
                        each App
                        Distributor will have the right (and will be deemed to <br />have accepted the right) to enforce
                        the
                        terms and conditions in this mobile application licence contained in <br />these Legal Terms
                        against you
                        as a third-party beneficiary thereof. <br /> <br />
                    </p>

                </div>
            </div>
           <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                <div>
                    <p> <br /></p>
                    <p><b>9. SOCIAL MEDIA <br /></b> <br /> <br />As part of the functionality of the services, you may
                        link
                        your account with online accounts you have with <br />third-party service providers (each such
                        account
                        is a 'Third-Party Account') by either: (1) providing your <br />third-party <br />Party Account
                        login
                        information through the Services; or (2) allowing us to access your third-party <br />Account,
                        as is
                        permitted under the applicable terms and conditions that govern your use of each third-party
                        <br />account. You represent and warrant that you are entitled to disclose your Third-Party
                        Account
                        login <br />information to us and/or grant us access to your Third-Party Account, without breach
                        by you
                        of any of the <br />terms and conditions that govern your use of the applicable Third-Party
                        Account, and
                        without obligating us <br />to pay any fees or making us subject to any usage limitations
                        imposed by the
                        third-party service provider of <br />the Third-Party Account. By granting us access to any
                        Third-Party
                        Accounts, you understand that (1) we <br />may access, make available, and store (if applicable)
                        any
                        content that you have provided to and stored in <br />your Third-Party Account (the 'Social
                        Network
                        Content') so that it is available on and through the Services <br />via your account, including
                        without
                        limitation any friend lists, and (2) we may submit to and receive from <br />your Third-Party
                        Account
                        additional information to the extent you are notified when you link your account <br />with the
                        Third-Party Account. Depending on the third-party accounts you choose and subject to the privacy
                        <br />settings that you have set in such third-party accounts, personally identifiable
                        information that
                        you post to <br />your third-party accounts may be available on and through your account on the
                        services. Please note that if <br />a third-party account or associated service becomes
                        unavailable or
                        our access to such a third-party account <br />is terminated by the third-party service
                        provider, then
                        social network content may no longer be available on <br />and through the services. You will
                        have the
                        ability to disable the connection between your account on the <br />services and your
                        third-party
                        accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH <br />THE THIRD-PARTY SERVICE
                        PROVIDERS
                        ASSOCIATED WITH YOUR THIRDPARTY ACCOUNTS IS <br />GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
                        THIRD
                        PARTY SERVICE PROVIDERS. <br />We make no effort to review any Social Network Content for any
                        purpose,
                        including but not limited to, for <br />accuracy, legality, or non-infringement, and we are not
                        responsible for any Social Network Content. You <br />acknowledge and agree that we may access
                        your
                        email address book associated with a Third-Party <br />Account and your contacts list stored on
                        your
                        mobile device or tablet computer solely for purposes of <br />identifying and informing you of
                        those
                        contacts who have also registered to use the Services. You can <br />deactivate the connection
                        between
                        the Services and your Third-Party Account by contacting us using the <br />contact information
                        below or
                        through your account settings (if applicable). We will attempt to delete any <br />information
                        stored on
                        our servers that was obtained through such Third-Party Account, except the username <br />and
                        profile
                        picture that become associated with your account. <br /> <br /> <br />
                    </p>
                    <p><b>10. SERVICES MANAGEMENT <br /></b> <br /> <br />We reserve the right, but not the obligation,
                        to: (1)
                        monitor the Services for violations of these Legal Terms; (2) <br />take appropriate legal
                        action
                        against anyone who, in our sole discretion, violates the law or these Legal Terms,
                        <br />including
                        without limitation, reporting such user to law enforcement authorities; (3) in our sole
                        discretion and
                        <br />without limitation, refuse, restrict access to, limit the availability of, or disable (to
                        the
                        extent technologically <br />feasible) any of your Contributions or any portion thereof; (4) in
                        our sole
                        discretion and without limitation, notice, <br />or liability, to remove from the Services or
                        otherwise
                        disable all files and content that are excessive in size or are <br />in any way burdensome to
                        our
                        systems; and (5) otherwise manage the Services in a manner designed to protect <br />our rights
                        and
                        property and to facilitate the proper functioning of the Services. <br /> <br /> <br />
                    </p>
                    <p><b>11. PRIVACY POLICY <br /></b> <br /> </p>

                </div>
            </div>
           <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                <div>
                    <p>We care about data privacy and security. Please review our Privacy Policy. By using the Services,
                        you
                        <br />agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms.
                        Please be
                        advised <br />the Services are hosted in India. If you access the Services from any other region
                        of the
                        world with laws or <br />other requirements governing personal data collection, use, or
                        disclosure that
                        differ from applicable laws in <br />India, then through your continued use of the Services, you
                        are
                        transferring your data to India, and you <br />expressly consent to have your data transferred
                        to and
                        processed in India. <br /> <br /> <br />
                    </p>
                    <p><b>12. TERM AND TERMINATION <br /></b> <br /> <br />These legal terms shall remain in full force
                        and
                        effect while you use the services. WITHOUT LIMITING <br />ANY OTHER PROVISION OF THESE LEGAL
                        TERMS, WE
                        RESERVE THE RIGHT TO, IN OUR SOLE <br />DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS
                        TO AND
                        USE OF THE SERVICES <br />(INCLUDING BLOCKING CERTAIN IP ADDRESSES) TO ANY PERSON FOR ANY REASON
                        OR FOR
                        <br />NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, <br />WARRANTY
                        OR
                        COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW <br />OR REGULATION. WE MAY
                        TERMINATE
                        YOUR USE OR PARTICIPATION IN THE SERVICES OR <br />DELETE YOUR ACCOUNT AND ANY CONTENT OR
                        INFORMATION
                        THAT YOU POSTED AT ANY TIME, <br />WITHOUT WARNING, IN OUR SOLE DISCRETION. <br /> <br />
                        <br />If we
                        terminate or suspend your account for any reason, you are prohibited from registering and
                        creating a
                        <br />new account under your name, a fake or borrowed name, or the name of any third party, even
                        if you
                        may be <br />acting on behalf of the third party. In addition to terminating or suspending your
                        account,
                        we reserve the <br />right to take appropriate legal action, including without limitation
                        pursuing
                        civil, criminal, and injunctive <br />redress. <br /> <br /> <br /> <br />
                    </p>
                    <p><b>13. MODIFICATIONS AND INTERRUPTIONS <br /></b> <br /> <br />We reserve the right to change,
                        modify, or
                        remove the contents of the services at any time or for any reason <br />at our sole discretion
                        and
                        without notice. However, we have no obligation to update any information on our <br />services.
                        We will
                        not be liable to you or any third party for any modification, price change, suspension, or
                        <br />discontinuance of the services. <br /> <br /> <br />We cannot guarantee that the services
                        will be
                        available at all times. We may experience hardware, <br />software, or other problems or need to
                        perform
                        maintenance related to the services, resulting in <br />interruptions, delays, or errors. We
                        reserve the
                        right to change, revise, update, suspend, discontinue, or <br />otherwise modify the services at
                        any
                        time or for any reason without notice to you. You agree that we have <br />no liability
                        whatsoever for
                        any loss, damage, or inconvenience caused by your inability to access or use the <br />services
                        during
                        any downtime or discontinuance of the services. Nothing in these legal terms will be
                        <br />construed to
                        obligate us to maintain and support the services or to supply any corrections, updates, or
                        <br />releases in connection therewith. <br /> <br /> <br /> <br />
                    </p>
                    <p><b>14. GOVERNING LAW <br /></b> <br /> </p>

                </div>
            </div>
           <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                <div>
                    <p>These Legal Terms shall be governed by and defined following the laws of India. Healthcare Club and
                        yourself
                        <br />irrevocably consent that the courts of India shall have exclusive jurisdiction to resolve
                        any
                        dispute which <br />may arise in connection with these Legal Terms. <br /> <br /> <br />
                    </p>
                    <p><b>15. DISPUTE RESOLUTION <br /></b> <br /> <br />You agree to irrevocably submit all disputes
                        related to
                        these Legal Terms or the legal relationship <br />established by these Legal Terms to the
                        jurisdiction
                        of the India courts. Healthcare Club shall also maintain the right <br />to bring proceedings as to the
                        substance of the matter in the courts of the country where you reside or, if <br />these Legal
                        Terms are
                        entered into in the course of your trade or profession, the state of your principal <br />place
                        of
                        business. <br /> <br /> <br /></p>
                    <p><b>16. CORRECTIONS <br /></b> <br /> <br />There may be information on the Services that contains
                        typographical errors, inaccuracies, or omissions, <br />including descriptions, pricing,
                        availability,
                        and various other information. We reserve the right to correct any <br />errors, inaccuracies,
                        or
                        omissions and to change or update the information on the Services at any time, <br />without
                        prior
                        notice. <br /> <br /> <br /></p>
                    <p><b>17. DISCLAIMER <br /></b> <br /> <br />THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE
                        BASIS.
                        YOU AGREE THAT YOUR <br />USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT
                        PERMITTED
                        BY <br />LAW, WE DISCLAIM ALL EXPRESS OR IMPLIED WARRANTIES IN CONNECTION WITH THE
                        <br />SERVICES AND
                        YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED <br />WARRANTIES OF
                        MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, AND <br />NONINFRINGEMENT WE MAKE NO WARRANTIES OR
                        REPRESENTATIONS
                        ABOUT THE ACCURACY <br />OR <br />COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY
                        WEBSITES
                        OR MOBILE <br />APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR
                        <br />RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND
                        <br />MATERIALS, (2)
                        PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, <br />RESULTING FROM YOUR ACCESS
                        TO AND
                        USE OF THE SERVICES, (3) ANY UNAUTHORIZED <br />ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR
                        ANY AND
                        ALL PERSONAL INFORMATION <br />AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION
                        OR
                        CESSATION OF <br />TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES,
                        OR THE
                        <br />LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, <br />AND/OR
                        (6) ANY
                        ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS <br />OR DAMAGE OF ANY KIND
                        INCURRED AS
                        A RESULT OF THE USE OF ANY CONTENT POSTED, <br />TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
                        THE
                        SERVICES. WE DO NOT WARRANT, <br />ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT
                        OR
                        SERVICE <br />ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED
                        <br />WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
                        <br />ADVERTISING,
                        AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR <br />MONITORING ANY TRANSACTION
                        BETWEEN
                        YOU AND ANY THIRD-PARTY PROVIDERS OF <br />PRODUCTS OR SERVICES YOU SHOULD USE YOUR BEST
                        JUDGMENT AND
                        EXERCISE CAUTION <br />WHERE APPROPRIATE WHEN PURCHASING A PRODUCT OR SERVICE THROUGH ANY MEDIUM
                        OR
                        <br />IN ANY ENVIRONMENT. <br />
                    </p>

                </div>
            </div>
           <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                <div>
                    <p> <br /> <br /></p>
                    <p><b>18. LIMITATIONS OF LIABILITY <br /></b> <br /> <br />IN NO EVENT WILL WE OR OUR DIRECTORS,
                        EMPLOYEES,
                        OR AGENTS BE LIABLE TO YOU OR ANY <br />THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
                        EXEMPLARY,
                        INCIDENTAL, SPECIAL, <br />OR <br />PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS
                        OF DATA,
                        OR OTHER DAMAGES <br />ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF
                        THE
                        POSSIBILITY <br />OF SUCH DAMAGES. <br /> <br /> <br /></p>
                    <p><b>19. INDEMNIFICATION <br /></b> <br /> <br />You agree to defend, indemnify, and hold us
                        harmless,
                        including our subsidiaries, affiliates, and all of our <br />respective officers, agents,
                        partners, and
                        employees, from and against any loss, damage, liability, claim, or <br />demand, including
                        reasonable
                        attorneys&#8217; fees and expenses, made by any third party due to or arising out <br />of: (1)
                        your
                        Contributions; (2) use of the Services; (3) breach of these Legal Terms; (4) any breach of your
                        <br />representations and warranties set forth in these Legal Terms; (5) your violation of the
                        rights of
                        a third party, <br />including but not limited to intellectual property rights; or (6) any overt
                        harmful
                        act toward any other user of <br />the Services with whom you connected via the Services.
                        Notwithstanding the foregoing, we reserve the <br />right, at your expense, to assume the
                        exclusive
                        defense and control of any matter for which you are <br />required to indemnify us, and you
                        agree to
                        cooperate, at your expense, with our defense of such claims. We <br />will use reasonable
                        efforts to
                        notify you of any such claim, action, or proceeding that is subject to this
                        <br />indemnification upon
                        becoming aware of it. <br /> <br /> <br /><b>20. USER DATA <br /></b> <br /> <br />We will
                        maintain
                        certain data that you transmit to the Services for the purpose of managing the performance
                        <br />of the
                        Services, as well as data relating to your use of the Services. Although we perform regular
                        routine
                        <br />backups of data, you are solely responsible for all data that you transmit or that relates
                        to any
                        activity you <br />have undertaken using the Services. You agree that we shall have no liability
                        to you
                        for any loss or <br />corruption of any such data, and you hereby waive any right of action
                        against us
                        arising from any such loss <br />or corruption of such data. <br /> <br /> <br />
                    </p>
                    <p><b>21. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES <br /></b> <br /> <br />Visiting
                        the
                        services, sending us emails, and completing online forms constitute electronic communications.
                        <br />You
                        consent to receive electronic communications, and you agree that all agreements, notices,
                        disclosures,
                        <br />and other communications we provide to you electronically, via email and on the Services,
                        satisfy
                        any legal <br />requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE
                        OF
                        <br />ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO <br />ELECTRONIC
                        DELIVERY OF
                        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR <br />COMPLETED BY US OR VIA THE
                        SERVICES
                        You hereby waive any rights or requirements under any <br />statutes, regulations, rules,
                        ordinances, or
                        other laws in any jurisdiction that require an original signature, <br />the delivery or
                        retention of
                        non-electronic records, or payments or the granting of credits by any means <br />other than
                        electronic
                        means. <br /> <br />
                    </p>

                </div>
            </div>
           <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
                <div>
                    <p><b>22. MISCELLANEOUS <br /></b> <br /> <br />These Legal Terms and any policies or operating
                        rules posted
                        by us on the Services or in respect to the <br />Services constitute the entire agreement and
                        understanding between you and us. Our failure to exercise or <br />enforce any right or
                        provision of
                        these Legal Terms shall not operate as a waiver of such right or provision. <br />These Legal
                        Terms
                        operate to the fullest extent permissible by law. We may assign any or all of our rights
                        <br />and
                        obligations to others at any time. We shall not be responsible or liable for any loss, damage,
                        delay, or
                        <br />failure to act caused by any cause beyond our reasonable control. If any provision or part
                        of a
                        provision of <br />these Legal Terms is determined to be unlawful, void, or unenforceable, that
                        provision or part of the <br />provision is deemed severable from these Legal Terms and does not
                        affect
                        the validity and enforceability of <br />any remaining provisions. There is no joint venture,
                        partnership, employment or agency relationship created <br />between you and us as a result of
                        these
                        Legal Terms or use of the Services. You agree that these Legal <br />Terms will not be construed
                        against
                        us by virtue of having drafted them. You hereby waive any and all <br />defences you may have
                        based on
                        the electronic form of these Legal Terms and the lack of signing by the <br />parties hereto to
                        execute
                        these Legal Terms. <br /> <br /> <br />
                    </p>
                    <p><b>23. CONTACT US <br /></b> <br /> In order to resolve a complaint regarding the services or to
                        receive
                        further information regarding their use, <br />please contact us at: <br /> <br /></p>
                    <p>&#9679; By visiting this page on our website: <a style={{ textDecoration: 'none' }}
                            href="https://healthcareclub.in" target="_blank">https://healthcareclub.in</a> <br />&#9679; By sending us
                        an
                        email at 
                        <a style={{ textDecoration: 'none' }} href="mailto:info@healthcareclub.in"> info@healthcareclub.in</a><br />
                    </p>
                    <p> <br /></p>
                    <p> </p>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Terms;