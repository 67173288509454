import React from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from '../common/TopBar';
import CustomNavbar from '../common/Navbar';
import Footer from '../common/Footer';
import { HelmetProvider } from 'react-helmet-async';

const OpenLayout = () => {
    const helmetContext = {};
    return (
        <>
            <HelmetProvider context={helmetContext}>

                <TopBar />
                <CustomNavbar />
                <Outlet />
                <Footer />
            </HelmetProvider>
        </>
    )
}

export default OpenLayout