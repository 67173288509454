import React from 'react';

const DetailBlog = ({ data }) => {
    const manipulateContent = () => {
        // Regular expression to find the <img> tags within the content string
        const imgRegex = /<img\s[^>]*src="([^"]*)"[^>]*>/g;

        // Apply the CSS style to each <img> tag found in the content string
        const modifiedContent = data.content.replace(
            imgRegex,
            '<img style="width:100%" src="$1">'
        );

        return modifiedContent;
    };

    return (
        <div className="mb-5 container" style={{ overflowX: 'hidden' }}>
            <img className="img-fluid w-100 mb-5" src={process.env.REACT_APP_BASE_URL + "blogImage/" + data.image} alt="" />
            <h1 className="mb-4">{data.title}</h1>
            <div className="content" dangerouslySetInnerHTML={{ __html: manipulateContent() }}></div>
        </div>
    );
};

export default DetailBlog;
