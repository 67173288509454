import React, { useEffect, useState } from 'react'
import { BlogList, prefillBlog } from './TempData'
import { Button, Form, Modal } from 'react-bootstrap'
import { MultiSelect } from 'react-multi-select-component'
import ReactSelect from 'react-select'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import axios from 'axios'
import Loader from './loader'
import toast from 'react-hot-toast'

const TextInput = [
  "title",
  "subtitle",
  "author_name",
  "url_key",
  "meta_title",
  "meta_description",
]

const Editable =
  [
    "title",
    "subtitle",
    "author_name",
    "url_key",
    "meta_title",
    "meta_description",


    "content",
    "image",
    "category",
    "tag",
    "blog_date",


    "created_by",
    "modified_by",


    "is_active",
    "is_featured",
    "banner_image",
    "meta_keywords",
  ]
const Dashboard = () => {

  const [tableHeading, setTableHeading] = useState(['id'].concat(TextInput.concat(["category", "tag", "blog_date", "meta_keywords", "image"])))
  const [tableData, setTableData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [addBlog, setAddBlog] = useState(false)
  const [modalData, setModalData] = useState({})
  const [multiSelect, setMultiSelect] = useState([])
  const [singleSelect, setSingleSelect] = useState({})
  const [multiSelect1, setMultiSelect1] = useState([])
  const [MasterData, setMasterData] = useState()
  const [singleSelectList, setSingleSelectList] = useState([])
  const [multiSelectList, setMultiSelectList] = useState([])
  // const [multiSelectList1, setMultiSelectList1] = useState([])
  const [loading, setLoading] = useState(false)
  const [imageName, setImageName] = useState('')


  const fetchTableDate = async () => {
    const bloglist = await axios.get(`${process.env.REACT_APP_BASE_URL}blog/apis/getBlogs`)
    //  console.log(bloglist);
    setTableData(bloglist.data.data)
  }

  const fetchMasterData = async () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}blog/apis/getDropdown`)
      .then((res) => {
        // console.log(res.data.data)
        setMasterData(res.data.data)
        setSingleSelectList(res.data.data['categoryMaster'].map(item => ({ value: item.category_name, label: item.category_name })))
        setMultiSelectList(res.data.data['tagMaster'].map(item => ({ value: item.tag_name, label: item.tag_name })))
        // setMultiSelectList1(res.data.data['metaKeywordMaster'].map(item => ({ value: item.keyword_name, label: item.keyword_name })))
        // console.log(MasterData)
      })
  }

  useEffect(async () => {
    setLoading(true)
    await fetchTableDate()
    await fetchMasterData()
    setLoading(false)
  }, [])


  const fetchPreFillData = (index) => {
    //  console.log(index)
    const selectedTableData = tableData.find(item => item.id === index.id)
    setModalData(selectedTableData)
    // const tempData = { value: 'Web Design', label: 'Web Design' }
    setSingleSelect(selectedTableData['category'] ? { value: selectedTableData['category'], label: selectedTableData['category'] } : {})
    //  console.log(selectedTableData);
    setMultiSelect(selectedTableData['tag']?.split(',').map(item => ({ value: item, label: item })) || [{}])
    setMultiSelect1(selectedTableData["meta_keywords"]?.split(',').map(item => ({ value: item, label: item })) || [{}])
    // setSingleSelect({ value: res.data.data.category, label: res.data.data.category })
  }

  const handleEditButton = (e) => {
    setLoading(true);
    setAddBlog(false)
    const index = tableData[parseInt(e.target.name)];
    //  console.log(index);
    fetchPreFillData(index)
    // setModalData(index)
    setShowModal(true)
    setLoading(false);
    return true
  }

  const handleActivate = (e) => {
    setLoading(true)
    const tempData = [...tableData];
    const index = tempData[parseInt(e.target.name)];
    console.log(index);
    axios.put(`${process.env.REACT_APP_BASE_URL}blog/apis/updateBlogStatus/${index.id}`, { is_active: !index.is_active })
      .then((res) => {
        toast.success('Blog Updated Successfully')
        index.is_active = !index.is_active;
        setTableData(tempData);
        setLoading(false)
      })
      .catch((err) => {
        toast.error('Something went wrong')
        setLoading(false)
      })
  }

  const handleAddBlog = () => {
    //  console.log('Add Blog')
    setAddBlog(true)
    setShowModal(true)
    setModalData({})
    setSingleSelect({})
    setMultiSelect([])
    // setMultiSelect1([])
  }

  const handleClose = () => {
    setShowModal(false)
    setAddBlog(false)
  }

  const handleFormSubmit = () => {
    
    // Check if any required field is empty
    const requiredFields = ['title', 'subtitle', 'author_name', 'url_key', 'meta_title', 'meta_description', 'category', 'blog_date', 'image', 'content'];
    const emptyFields = requiredFields.filter(field => !modalData[field]);

    if (emptyFields.length > 0) {
      emptyFields.forEach(field => {
        toast.error(`Please enter ${field.split('_').join(" ")} field`);
      });
      return false;
    }

    // Check if URL key starts with "/"
    if (!modalData['url_key'].startsWith('/')) {
      toast.error('URL Key must start with "/"');
      return false;
    }

    setLoading(true);

    const formData = new FormData();
    for (const key in modalData) {
      formData.append(key, modalData[key]);
    }
    // Set isPublished based on the state
    formData.append('isPublished', modalData.isPublished ? true : false);

    if (addBlog) {
      axios.post(`${process.env.REACT_APP_BASE_URL}blog/apis/addBlog`, formData)
        .then((res) => {
          setLoading(false);
          setShowModal(false);
          toast.success('Blog Added Successfully');
          fetchTableDate();
          setAddBlog(false);
        })
        .catch((err) => {
          toast.error('Something went wrong');
          setLoading(false);
        });
    } else {
      axios.put(`${process.env.REACT_APP_BASE_URL}blog/apis/updateBlog`, formData)
        .then((res) => {
          setLoading(false);
          setShowModal(false);
          toast.success('Blog Updated Successfully');
          fetchTableDate();
          setAddBlog(false);
        })
        .catch((err) => {
          toast.error('Something went wrong');
          setLoading(false);
        });
    }
    return true;
  };


  const handleAddFormChange = (e) => {
    const tempData = { ...modalData }
    if (e.target.name === 'publishPostToggle') {
      tempData["isPublished"] = e.target.checked;
    }
    else if (e.target.name === 'url_key' && !e.target.value.startsWith('/')) {
      const value = '/' + e.target.value;
      tempData[e.target.name] = value;
    }
    else {
      tempData[e.target.name] = e.target.value;
    }
    setModalData(tempData)
  }

  const handleImage = (e) => {
    const tempData = { ...modalData }

    tempData["image"] = e.target.files[0]
    console.log(e.target.files)
    setImageName(e.target.files[0])
    setModalData(tempData)
  }

  const handleContentChange = (e) => {
    const tempData = { ...modalData }
    tempData["content"] = e
    setModalData(tempData)
  }

  const handleMultiSelectChange = (e) => {
    console.log(e);
    const tempData = { ...modalData }
    setMultiSelect(e)
    tempData["tag"] = e.map(item => item.value)
    setModalData(tempData)
  }

  const handleMultiSelectChange1 = (e) => {
    //  console.log(e);
    const tempData = { ...modalData }
    setMultiSelect1(e)
    tempData["meta_keyword"] = e.map(item => item.value)
    setModalData(tempData)
  }

  const handleSelectChange = (e) => {
    //  console.log(e);
    const tempData = { ...modalData }
    setSingleSelect(e)
    tempData["category"] = e?.["value"]
    setModalData(tempData)
  }


  return (
    <>
      {/* <SEO title="Dashboard" description="Dashboard" name="Healthcare Club" type="control" /> */}
      <div className='' style={{ overflow: "clip", maxWidth: "85vw" }}>
        <div className='p-3 h-100 bg-secondary w-100' style={{ overflow: "clip" }}>
          {/* <AddBlog /> */}
          <div className="row align-items-center">
            <div className="col-sm-6 col-6 mb-4 mb-sm-0 d-flex justify-content-start">

              <h1 className="h1 mb-0 ls-tight">Blogs</h1>
            </div>

            <div className="col-sm-6 col-6 d-flex justify-content-end text-sm-end">
              <div className="mx-n1 border">
                <button onClick={handleAddBlog} className="btn d-inline-flex border-base px-1 py-1 btn-primary text-light">
                  <span className="h5 pe-1 my-auto">
                    <i className="bi bi-plus text-white fw-bold"></i>
                  </span>
                  <span className='h5 me-1 my-auto text-white'>Create</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive w-full mt-5 mx-2 mb-8" style={{ overflowX: "scroll" }}>
          <table className="table w-full table-bordered table-hover table-striped" style={{ overflowX: "scroll" }}>
            <thead className='my-10 h-3'>
              <tr className='text-center w-100'>
                <th className='w-100 my-2 text-nowrap'>S. No</th>
                {
                  tableHeading.map((item, index) => {
                    return (
                      <th className='text-center w-100 my-2  text-nowrap text-capitalize' key={index}>{item.split('_').join(" ")}</th>
                    );
                  })
                }
                <th className='w-100 my-2 text-nowrap'>Edit</th>
                <th className='w-100 my-2 text-nowrap'>Action</th>
              </tr>
            </thead>
            <tbody className=''>
              {
                tableData.map((item, index) => {
                  return (
                    <tr className='text-center w-100' key={index}>
                      <td className='text-center'>{index + 1}.</td>
                      {
                        tableHeading.map((field, index) => {
                          return (
                            <td className='text-center w-100' key={index}>
                              <div className='w-100 text-nowrap'>
                                {
                                  field === "image" ?
                                    <>
                                      <img src={process.env.REACT_APP_BASE_URL + 'blogImage/' + item[field]} alt={item[field]} style={{ width: "90%", cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#imageExample" />

                                      {/* modal */}
                                      <div className='modal fade' id='imageExample' tabIndex="-1" aria-hidden="true">
                                        <div className='modal-dialog'>
                                          <div className="modal-content">
                                            <div className="modal-body">
                                              <button type='button' className='btn-close' data-bs-dismiss="modal" aria-label='Close'></button>

                                              <img src={process.env.REACT_APP_BASE_URL + 'blogImage/' + item[field]} alt={item[field]} className='d-block w-100' />

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                    : field === "blog_date" ? item[field].split('T')[0] : item[field]}
                              </div>
                            </td>
                          );
                        })
                      }
                      <td className=''>
                        <button onClick={handleEditButton} name={index} className='btn px-2 py-1 mx-auto justify-content-center d-flex btn-primary'>Edit</button>
                      </td>
                      {/* {
                        isActivePresent && */}
                      <td className='w-50'>
                        <button value={item.is_active} name={index} onClick={handleActivate} className={`btn px-2 py-1 mx-auto justify-content-center d-flex ${item.is_active ? "btn-danger" : "btn-primary"}`}>{item.is_active ? "Deactivate" : "Activate"}</button>
                      </td>
                      {/* } */}
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <Modal
          className='w-full'
          show={showModal}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size='xl'
        >
          <Modal.Header closeButton>
            <Modal.Title>{addBlog ? 'Add' : 'Edit'} Blog</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='w-full d-flex flex-wrap'>
              <div className='form-floating mx-2 mb-3 w-45 text-capitalize' style={{ width: "48%" }}>
                <input
                  type="text"
                  name='title'
                  required
                  placeholder={`Enter a title ...`}
                  onChange={handleAddFormChange}
                  value={modalData["title"] || ""}
                  className="form-control w-100"
                // disabled={Editable.includes(item)}
                />
                <label htmlFor='title'>title</label>
              </div>
              <div className='form-floating mx-2 mb-3 w-45 text-capitalize' style={{ width: "48%" }}>
                <input
                  type="text"
                  name='subtitle'
                  required
                  placeholder={`Enter a subtitle ...`}
                  onChange={handleAddFormChange}
                  value={modalData["subtitle"] || ""}
                  className="form-control w-100"
                // disabled={Editable.includes(item)}
                />
                <label htmlFor='title'>subtitle</label>
              </div>
              <div className={`form-floating mx-2 mb-3 w-45 text-capitalize`} style={{ width: "48%" }}>
                <input
                  type="text"
                  name='author_name'
                  required
                  placeholder={`Enter a Author Name ...`}
                  onChange={handleAddFormChange}
                  value={modalData["author_name"] || ""}
                  className="form-control w-100"
                // disabled={Editable.includes(item)}
                />
                <label htmlFor='title'>author name</label>
              </div>
              <div className={`form-floating mx-2 mb-3 w-45 text-capitalize`} style={{ width: "48%" }}>
                <input
                  type="text"
                  name='url_key'
                  required
                  placeholder={`Enter a url Key ...`}
                  onChange={handleAddFormChange}
                  value={modalData["url_key"] || "/"}
                  className="form-control w-100"
                // disabled={Editable.includes(item)}
                />
                <label htmlFor='title'>url key</label>
              </div>
              <div className={`form-floating mx-2 mb-3 w-45 text-capitalize`} style={{ width: "48%" }}>
                <input
                  type="text"
                  name='meta_title'
                  required
                  placeholder={`Enter a meta title ...`}
                  onChange={handleAddFormChange}
                  value={modalData["meta_title"] || ""}
                  className="form-control w-100"
                // disabled={Editable.includes(item)}
                />
                <label htmlFor='title'>meta title</label>
              </div>
              <div className={`form-floating mx-2 mb-3 w-45 text-capitalize`} style={{ width: "48%" }}>
                <input
                  type="text"
                  name='meta_description'
                  required
                  placeholder={`Enter a meta description ...`}
                  onChange={handleAddFormChange}
                  value={modalData["meta_description"] || ""}
                  className="form-control w-100"
                // disabled={Editable.includes(item)}
                />
                <label htmlFor='title'>meta description</label>
              </div>

              <div className={`form-floating mx-2 mb-3 w-45 text-capitalize d-flex flex-column`} style={{ width: "48%" }}>
                <div htmlFor='multiSelect'>category</div>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="category"
                  // defaultValue={modalData["category"]}
                  value={singleSelect}
                  isDisabled={false}
                  isClearable={true}
                  isSearchable={true}
                  name="category"
                  options={singleSelectList}
                  onChange={handleSelectChange}
                />
              </div>
              <div className={`form-floating mx-2 mb-3 text-capitalize d-flex flex-column`} style={{ width: "48%" }}>
                <div htmlFor='multiSelect'>Tags</div>
                <MultiSelect
                  options={multiSelectList}
                  value={multiSelect}
                  onChange={handleMultiSelectChange}
                  labelledBy="multiSelect"
                  id="multiSelect" // Make sure id is set htmlFor the MultiSelect component
                  placeholder="Tags"
                />
              </div>

              <div className={`form-floating mx-2 mb-3 text-capitalize d-flex flex-column`} style={{ width: "48%", height: "3rem" }}>
                {/* <input
                  type="text"
                  name='meta_keyword'
                  placeholder={`Enter the meta keywords ...`}
                  onChange={handleAddFormChange}
                  value={modalData["meta_keyword"] || ""}
                  className="form-control w-100"
                />
                <label htmlFor='title'>meta keywords</label> */}
                <div className='d-flex'>
                  <input
                    type="checkbox"
                    id="publishPostToggle"
                    name="publishPostToggle"
                    onChange={handleAddFormChange}
                    checked={modalData["isPublished"] || false}
                    className="form-check-input me-2"
                  />
                  <label htmlFor="publishPostToggle" className="form-check-label">
                    Publish this post
                  </label>
                </div>
              </div>

              <div className={`form-floating mx-2 mb-3 w-45 text-capitalize`} style={{ width: "48%" }}>
                <input
                  type="date"
                  name='blog_date'
                  required
                  placeholder={`Enter a Blog Date ...`}
                  onChange={handleAddFormChange}
                  value={modalData["blog_date"]?.split("T")[0] || ""}
                  className="form-control w-100"
                // disabled={Editable.includes(item)}
                />
                <label htmlFor='title'>blog date</label>
              </div>
              <div className={`form-floating mx-2 mb-3 w-45 text-capitalize`} style={{ width: "98%" }}>
                <input
                  type="file"
                  name='image'
                  required
                  placeholder={`Enter a Blog Date ...`}
                  onChange={handleImage}
                  // defaultValue={imageName.name}
                  // value={imageName.name}
                  className="form-control w-100"
                // disabled={Editable.includes(item)}
                />
                <label htmlFor='title'>Image</label>
              </div>
              <div className='form-floating mx-2 mb-3 w-100' style={{ minHeight: "10rem" }}>
                <ReactQuill
                  style={{ height: "90%" }}
                  theme="snow"
                  value={modalData["content"]}
                  onChange={handleContentChange}
                  modules={{
                    toolbar: [
                      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                      [{ size: [] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' },
                      { 'indent': '-1' }, { 'indent': '+1' }],
                      ['link', 'image', 'video'],
                      ['clean']
                    ],
                    clipboard: {
                      // toggle to add extra line breaks when pasting HTML:
                      matchVisual: false,
                    }
                  }}
                  formats={[
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                    'link', 'image', 'video'
                  ]}
                  placeholder='Write something awesome ...'
                />
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {
              loading ? <Loader /> :
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleFormSubmit}>
                    Save Changes
                  </Button>
                </>
            }
          </Modal.Footer>
        </Modal>
        {loading && <Loader />}
      </div >
    </>
  )
}

export default Dashboard