import React from 'react'
import { Link } from 'react-router-dom'
import TeamMembers from '../components/core/TeamMembers'
import AboutInfo from '../components/core/AboutInfo'
import SEO from '../components/shared/helmet'

const About = () => {
    return (
        <>
            <SEO title='Healthcare Club | About Us' description={`Welcome to Healthcare Club, your trusted health companion! At Healthcare Club, we understand the importance of managing your health and well-being effectively. That's why we've developed an innovative and user-friendly app designed to empower you to take control of your health journey. Whether you're looking to track your vitals, keep a record of prescriptions, manage lab reports, or simply set reminders for your medical concerns, Healthcare Club has got you covered.`} />
            <div style={{ maxWidth: "1180px", margin: "0 auto" }} className='mt-4'>
                <div className='w-100'>
                    <p><b>ABOUT US </b> <br /></p>
                    <p>Welcome to Healthcare Club, your trusted health companion! <br /></p>
                    <p>At Healthcare Club, we understand the importance of managing your health and well-being effectively.
                        That's why <br />we've developed an innovative and user-friendly app designed to empower you to
                        take control of your <br />health journey. Whether you're looking to track your vitals, keep a
                        record of prescriptions, manage lab <br />reports, or simply set reminders for your medical
                        concerns, Healthcare Club has got you covered. <br /></p>
                    <p> <br /></p>
                    <p><b>Why Choose Healthcare Club? </b> <br /></p>
                    <p>1. <b>Track Your Vitals:</b> <br /></p>
                    <p>Your health is your wealth, and monitoring it has never been easier. With Healthcare Club, you can
                        effortlessly keep <br />tabs on your vital signs, ensuring you're always aware of your current
                        health status. Whether it's your blood <br />pressure, heart rate, temperature, or any other
                        vital metric, Healthcare Club provides a convenient platform for you <br />to track and maintain a
                        comprehensive record. <br /></p>
                    <p>2. <b>Prescription Management:</b> <br /></p>
                    <p>Managing prescriptions can be a hassle, but not with Healthcare Club. Our app allows you to maintain an
                        organized <br />record of all your medications. Say goodbye to the days of misplaced
                        prescriptions or missed dosages. <br />HVault keeps everything in one place, making it simple to
                        stay on top of your prescribed treatments. <br /></p>
                    <p>3. <b>Lab Report Management:</b> <br /></p>
                    <p>Lab reports are a crucial aspect of your healthcare journey, and we know how important it is to
                        have easy <br />access to them. With Healthcare Club, you can securely store and manage your lab
                        reports, ensuring that you have <br />a complete overview of your health history at your
                        fingertips. No more digging through piles of paperwork; <br />your lab reports are now just a
                        click away. <br /></p>
                    <p>4. <b>Medical Reminders:</b> <br /></p>
                    <p>Life can get busy, and it's easy to forget about essential medical appointments or medication
                        schedules. <br />HVault offers a personalized reminder system that helps you stay on track with
                        your health commitments. <br />Set reminders for doctor's appointments, medication doses, or any
                        other medical concerns, and let Healthcare Club <br />take care of the rest. <br /></p>
                    <p><b>Our Mission </b> </p>

                </div>
                <div>
                    <p>At Healthcare Club, our mission is to empower individuals to take charge of their health by providing
                        them with the <br />tools and resources they need for effective self-care. We believe that by
                        offering a comprehensive platform <br />for health management, we can enhance the quality of
                        life for our users and promote healthier communities. <br /></p>
                    <p><b>Your Health, Your Vault </b> <br /></p>
                    <p>Your health journey is unique, and Healthcare Club is here to support you every step of the way. Join the
                        thousands <br />of users who have already discovered the benefits of our app and experience the
                        convenience, security, <br />and peace of mind that come with managing your health through
                        Healthcare Club. <br />Thank you for choosing Healthcare Club as your health partner. We look forward to helping
                        you achieve your health <br />and wellness goals. <br /> </p>

                </div>
            </div>
        </>
    )
}

export default About