const BlogList = [
    {
        id: 1,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        categories: 'web Design'
    },
    {
        id: 2,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        categories: 'web Design'
    },
    {
        id: 3,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        categories: 'web Design'
    },
    {
        id: 4,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        categories: 'web Design'
    },
    {
        id: 5,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        categories: 'web Design'
    },
    {
        id: 6,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        categories: 'web Design'
    },
    {
        id: 7,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        categories: 'web Design'
    },
    {
        id: 8,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        categories: 'web Design'
    },
    {
        id: 9,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        categories: 'web Design'
    },
    {
        id: 10,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        categories: 'web Design'
    },
    {
        id: 11,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        categories: 'web Design'
    },
    {
        id: 12,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        categories: 'web Design'
    },
    {
        id: 13,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        categories: 'web Design'
    },
    {
        id: 14,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        categories: 'web Design'
    },
    {
        id: 15,
        title: 'Blog 1',
        img_url: 'https://via.placeholder.com/150',
        blogDate: '2021-09-01',
        createdBy: 'Admin',
        category: 'web Design'
    }
]

const prefillBlog = {
    id: 1,
    title: 'Blog 1',
    author_name:"Admin",
    // image: 'https://via.placeholder.com/150',
    url_key: 'blog-1',
    blog_date: '2021-09-01',
    category: 'web design',
    tags: ["web_design"],
    content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, quae. Quam, quia'
}

module.exports = { BlogList, prefillBlog }