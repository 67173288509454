import React from 'react'
import SEO from '../components/shared/helmet'

const Contact = () => {
  return (
    <div>
      <SEO title={'Healthcare Club | Contact Us'} description={'Contact us for any queries'} />
      {/* <!-- Contact Start --> */}
      <div className="container-fluid bg-secondary px-0">
        <div className="row g-0 ">
          <div className="col-lg-6 py-6 px-5 ">
            <h1 className="display-5 mb-4">Contact For Any Queries</h1>
            <form>
              <div className="row g-3">
                <div className="col-6">
                  <div className="form-floating">
                    <input type="text" className="form-control" id="form-floating-1" placeholder="John Doe" />
                      <label for="form-floating-1">Full Name</label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-floating">
                    <input type="email" className="form-control" id="form-floating-2" placeholder="name@example.com" />
                      <label for="form-floating-2">Email address</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <input type="text" className="form-control" id="form-floating-3" placeholder="Subject" />
                      <label for="form-floating-3">Subject</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <textarea className="form-control" placeholder="Message" id="form-floating-4" style={{height: "150px"}}></textarea>
                    <label for="form-floating-4">Message</label>
                  </div>
                </div>
                <div className="col-12">
                  <button className="btn btn-primary w-100 py-3" type="submit">Submit</button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-6" style={{minHeight: "400px"}}>
            <div className="position-relative h-100">
              <iframe className="position-relative w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.936040781733!2d77.2935170754209!3d28.511571975731034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1e0be04c813f%3A0x7077f30ccd13d6ba!2sMittal%20Software%20Labs%20LLP!5e0!3m2!1sen!2sin!4v1709710511497!5m2!1sen!2sin"
                frameborder="0" style={{border:"0"}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact