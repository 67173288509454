import './App.css';
import {Routes, Route} from "react-router-dom"
import Home from './pages/Home';
// import Footer from './components/common/Footer';
import PageTop from './components/common/PageTop';
// import CustomNavbar from './components/common/Navbar';
// import TopBar from './components/common/TopBar';
import About from './pages/About';
import Contact from './pages/Contact';
import Blogs from './pages/Blogs';
// import DetailBlog from './pages/DetailBlog';
import Login from './pages/Login';
import Error from './pages/Error'
import Dashboard from './pages/Dashboard'
import OpenLayout from './components/core/OpenLayout'
import Layout from './components/Auth/Layout'
import BlogRouter from './pages/BlogRouter';
import MasterData from './pages/masterData';
import FAQs from './pages/FAQs'

import PrivateRoute from './components/Auth/PrivateRoute';
import PrivacyPolicy from './pages/privacy-policy';
import Terms from './pages/terms-and-conditions';

// import AddBlog from './pages/Blog/AddBlog';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<OpenLayout />}>
          <Route index element={<Home/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/blog" element={<Blogs/>}/>
          <Route path="/blogdetail/:url_key" element={<BlogRouter/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/FAQs" element={<FAQs/>}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/terms-and-conditions" element={<Terms/>}/>
        </Route>
        
        {/* Private Route - for Only Logged in User */}
        <Route element={<PrivateRoute />}>
          <Route path="/admin" element={<Layout />} >
              <Route index element={<Dashboard />}/>
              <Route path='/admin/master' element={<MasterData />}/>
          </Route>
        </Route>

        {/* 404 Page */}
        <Route path="*" element={<Error />} />
      </Routes>

      {/* <Footer /> */}
      <PageTop />
    </div>
  );
}

export default App;
