import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import ReactSelect from 'react-select';
import Loader from './loader';

const disable = ["id"]

const MasterData = () => {
    const headingList = ['Name', 'Age', 'Location'];
    const [tableHeading, setTableHeading] = useState(headingList);
    const [tableData, setTableData] = useState([]);
    const [totalData, setTotalData] = useState({});
    const [masterTableList, setMasterTableList] = useState([]);
    const [currentMasterTable, setCurrentMasterTable] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [modalData, setModalData] = useState({});

    const fetchCategory = () => {
        console.log("fetchCategory");
        setLoading(true);
        axios.get(process.env.REACT_APP_BASE_URL + 'apis/master/getMaster')
            .then((response) => {
                console.log(response.data);
                setTotalData(response.data.data);
                console.log(Object.keys(response.data.data));
                setMasterTableList(Object.keys(response.data.data).map(item => ({ label: item.split('_').join(" "), value: item }))); // Add a semicolon at the end of this line
                setCurrentMasterTable(Object.keys(response.data.data)[0]);
                setLoading(false);
            }).catch((error) => {
                toast.error("Error in fetching data");
                setLoading(false);
            })
    }


    useEffect(() => {
        console.log("currentMasterTable: ", currentMasterTable);
        if (currentMasterTable) {
            setTableData(totalData[currentMasterTable]);
            console.log("tableData: ", tableData);
            setTableHeading(Object.keys(totalData[currentMasterTable][0]).filter(item => item !== "is_active"));
        }
    }, [currentMasterTable, totalData]);


    useEffect(() => {
        fetchCategory();
    }, []);

    const handleAddBlog = () => {
        setAddNew(true);
        setShowModal(true);
        setModalData({});
    }
    const handleEditButton = (e) => {
        setShowModal(true);
        setAddNew(false);
        setModalData(tableData[e.target.name]);
    }
    const handleActivate = (e) => {
        setLoading(true);
        axios.put(process.env.REACT_APP_BASE_URL + 'apis/master/updateMasterStatus',
            {
                id: tableData[e.target.name].id,
                is_active: !tableData[e.target.name].is_active,
                master_table_name: currentMasterTable
            }
        )
            .then((response) => {
                // fetchCategory();
                const tempData = [...tableData];
                tempData[e.target.name].is_active = !tempData[e.target.name].is_active;
                setTotalData({ ...totalData, [currentMasterTable]: tempData });
                setTableData(tempData);
                toast.success("Data updated successfully");
                setLoading(false);
            })
            .catch((error) => {
                toast.error("Error in updating data");
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            })
    }
    const handleSelectChange = (e) => {
        console.log(e);
        setCurrentMasterTable(e.value)
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setAddNew(false);
    }

    const handleChange = (e) => {
        setModalData({ ...modalData, [e.target.name]: e.target.value });
    }

    const handleSubmitForm = () => {
        // currentMasterTable
        const isInputExists = tableData.some(item => item.name === modalData.name)
        if (isInputExists) {
            toast.error("Name already exists");
            console.log("Input already exists in tableData");
            return;
        }
        if (addNew) {
            setLoading(true);
            axios.post(process.env.REACT_APP_BASE_URL + 'apis/master/' + (currentMasterTable === "category_master" ? 'createCategory' : 'createTag'), modalData)
                .then((response) => {
                    setShowModal(false);
                    fetchCategory();
                    setLoading(false);
                    toast.success("Data updated successfully");
                }
                ).catch((error) => {
                    toast.error("Error in updating data");
                    setLoading(false);
                })
            console.log("Add new");
            console.log(modalData);
        } else {
            setLoading(true);
            axios.put(process.env.REACT_APP_BASE_URL + 'apis/master/' + (currentMasterTable === "category_master" ? 'updateCategory' : 'updateTag'), modalData)
                .then((response) => {
                    setShowModal(false);
                    fetchCategory();
                    setLoading(false);
                    toast.success("Data updated successfully");
                }
                ).catch((error) => {
                    toast.error("Error in updating data");
                    setLoading(false);
                })
        }

    }
    return (
        <>
            <div className='' style={{ maxWidth: "85vw" }}>
                <div className='p-3 h-100 bg-secondary w-100' style={{ overflow: "clip" }}>
                    {/* <AddBlog /> */}
                    <div className="row align-items-center">
                        <div className="col-sm-6 col-6 mb-4 mb-sm-0 d-flex justify-content-start">

                            <h1 className="h1 mb-0 ls-tight">Blog Master</h1>
                        </div>


                    </div>
                </div>
                <div className='d-flex justify-content-between w-full mx-5 mt-3'>
                    <div className='h-full w-25 my-auto'>
                        <ReactSelect
                            className="basic-single w-full text-capitalize"
                            classNamePrefix="masterTable"
                            // defaultValue={modalData["category"]}
                            value={{ label: currentMasterTable.split('_').join(" "), value: currentMasterTable }}
                            isDisabled={false}
                            isClearable={false}
                            isSearchable={true}
                            name="category"
                            options={masterTableList}
                            onChange={handleSelectChange}
                        />
                    </div>
                    <div className="d-flex text-sm-end">
                        <div className="mx-n1 border">
                            <button onClick={handleAddBlog} className="btn d-inline-flex border-base px-1 py-1 btn-primary text-light">
                                <span className="h5 pe-1 my-auto">
                                    <i className="bi bi-plus text-white fw-bold"></i>
                                </span>
                                <span className='h5 me-1 my-auto text-white'>Create</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="table-responsive w-full mt-2 mx-2 mb-8 h-full" >
                    <table className="table w-full table-bordered table-hover table-striped" >
                        <thead className='my-10 h-3'>
                            <tr className='text-center w-100'>
                                <th className=' my-2 text-nowrap'>S. No</th>
                                {
                                    tableHeading.map((item, index) => {
                                        return (
                                            <th className='text-center my-2  text-nowrap text-capitalize' key={index}>{item.split('_').join(" ")}</th>
                                        );
                                    })
                                }
                                <th className='w-25 my-2 text-nowrap'>Edit</th>
                                <th className='w-25 my-2 text-nowrap'>Action</th>
                            </tr>
                        </thead>
                        <tbody className=''>
                            {
                                tableData.map((item, index) => {
                                    return (
                                        <tr className='text-center' key={index}>
                                            <td className='text-center'>{index + 1}.</td>
                                            {
                                                tableHeading.map((field, index) => {
                                                    return (
                                                        <td className='text-center' key={index}>
                                                            <div className='w-100 text-nowrap'>
                                                                {
                                                                    item[field]
                                                                }
                                                            </div>
                                                        </td>
                                                    );
                                                })
                                            }
                                            <td className='w-25'>
                                                <button onClick={handleEditButton} name={index} className='btn px-2 py-1 mx-auto justify-content-center d-flex btn-primary'>Edit</button>
                                            </td>
                                            {/* {
                                        isActivePresent && */}
                                            <td className='w-25'>
                                                <button value={item.is_active} name={index} onClick={handleActivate} className={`btn px-2 py-1 mx-auto justify-content-center d-flex ${item.is_active ? "btn-danger" : "btn-primary"}`}>{item.is_active ? "Deactivate" : "Activate"}</button>
                                            </td>
                                            {/* } */}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <Modal
                    className='modal-dialog-centered'
                    size='lg'
                    show={showModal}
                    onHide={handleCloseModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {addNew ? 'Add' : 'Edit'} Blog
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            tableHeading.map(input =>
                                <div className='form-group'>
                                    <label className='text-capitalize' htmlFor={input}>{input.split('_').join(' ')}</label>
                                    <input disabled={disable.includes(input.toLowerCase())} onChange={handleChange} value={modalData[input]} type='text' name={input} id={input} className='form-control' />
                                </div>
                            )}
                        {/* <div className='form-group'>
                        <label htmlFor='description'>Description</label>
                        <textarea name='description' id='description' className='form-control' />
                    </div> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={handleSubmitForm} className='btn btn-primary'>Save</button>
                        <button onClick={handleCloseModal} className='btn btn-secondary'>Cancel</button>
                    </Modal.Footer>
                </Modal>
                {loading && <Loader />}
            </div>
        </>
    );
};

export default MasterData;