import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login } from '../services/operations/authAPI'

const Login = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        username: "",
        password: "",
    })

    // const [showPassword, setShowPassword] = useState(false);

    const { username, password } = formData

    const handleOnChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }))
    }

    const handleOnSubmit = (e) => {
        e.preventDefault()
        dispatch(login(username, password, navigate))
        console.log('login successfull')
    }

    return (
        <div>
            <section className="" style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div className="container py-5 ">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-5">
                            <div className="card" style={{ borderRadius: "1rem", border: "none" }}>
                                <div className="row g-0">
                                    <div className="col-md-12 col-lg-12 d-flex align-items-center">
                                        <div className="card-body p-4 p-lg-5 text-black border">

                                            <form method="post" onSubmit={handleOnSubmit}>
                                                <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: "1px", textAlign: "center", fontSize: "45px" }}>Admin</h5>
                                                <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: "1px" }}>Sign into your account</h5>
                                                <div className="form-outline mb-4">
                                                    <label className="form-label" for="form2Example17">Username</label>
                                                    <input type="name" name="username" id="form2Example17" className="form-control form-control-lg" onChange={handleOnChange}/>
                                                </div>

                                                <div className="form-outline mb-4 password-container">
                                                    <label className="form-label" for="form2Example27">Password</label>
                                                    <input type="password" name="password" className="form-control form-control-lg" id="password" required="" onChange={handleOnChange}/>

                                                </div>

                                                <div className="pt-1 mb-4">
                                                    <button type="submit" name="submit" className="btn  btn-lg w-100 bg-primary" style={{ color: "#ffffff" }}>Login</button>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Login