import React from "react";
import { Link } from "react-router-dom";
import image from '../assets/images/app-mockup.png'
import { Helmet } from "react-helmet-async";
import playStoreBtn from '../assets/button/playstore-btn.png'
import appStoreBtn from '../assets/button/apple-btn.png'

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Healthcare Club | Home</title>
                <meta name="description" content="Welcome to Healthcare Club. In our interconnected world, it's heartening to see technology play a pivotal role in saving lives. With Healthcare Club, finding a blood donor or offering to donate has never been easier. Whether you're in a dire situation requiring immediate blood transfusion or you're someone willing to extend a helping hand, our platform simplifies the process, making it seamless and efficient." />
            </Helmet>
            <div className="h-100">
                <section className="banner">
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-6">
                                <h1 style={{ fontSize: "3.2rem" }}>Welcome to Healthcare Club</h1>
                                <p className="my-4">In our interconnected world, it's heartening to see technology play a pivotal role in saving
                                    lives. With <b>Healthcare Club</b>, finding a blood donor or offering to donate has never been easier.
                                    Whether you're in a dire situation requiring immediate blood transfusion or you're someone
                                    willing to extend a helping hand, our platform simplifies the process, making it seamless and
                                    efficient.</p>
                                <a className="me-4 mt-4" href="https://play.google.com/store/apps/details?id=com.healthcareclub" target="_blank">
                                    <img style={{width: "200px"}} src={playStoreBtn} alt="" />
                                </a>
                                <a href="#" target="_blank">
                                    <img style={{width: "200px"}} src={appStoreBtn} alt="" />
                                </a>
                            </div>
                            <div className="col-lg-6">
                                <img src={image} alt="App Screenshot" className="img-fluid" id="app-mockup" />
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    );
};

export default Home;
