import React, { useEffect, useState } from 'react'
import Blog1 from "../assets/img/blog-1.jpg"
import { Link, Navigate } from 'react-router-dom'
// import { BlogList } from './TempData'
import DetailBlog from './DetailBlog'
import axios from 'axios'
import Loader from './loader'
import SEO from '../components/shared/helmet'
const host = process.env.REACT_APP_BASE_URL

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']


const Blogs = () => {

    const [blogList, setBlogList] = useState([])
    const [currentBlogList, setCurrentBlogList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageList, setCurrentPageList] = useState([])
    const [tagsList, setTagsList] = useState([])
    const [categoriesList, setCategoriesList] = useState([])
    const [currentCategory, setCurrentCategory] = useState('')
    const [currentTags, setCurrentTags] = useState([])
    const [searchInput, setSearchInput] = useState('')
    const [activeBlog, setActiveBlog] = useState(false)
    const [id, setId] = useState()
    const [loading, setLoading] = useState(false)
    const [readMoretags, setReadMoreTags] = useState(false)

    const fetchBlogList = async () => {
        setLoading(true)
        let blogs = await axios.get(`${host}getAllBlogs`)
        console.log(blogs)
        const BlogList = blogs.data.data.sort((a, b) => new Date(b.blog_date) - new Date(a.blog_date))
        setBlogList(BlogList)
        setCurrentBlogList(BlogList.slice(10 * (currentPage - 1), 10 * (currentPage - 1) + 10))
        let pageList = []
        for (let i = 0; i <= BlogList.length / 10; i++) {
            pageList.push(i + 1)
        }
        console.log(pageList);
        setCurrentPageList(pageList)
        setLoading(false)
    }

    const fetchCategoriesList = async () => {
        setLoading(true)
        const res = await axios.get(`${host}getDropdown`)
        console.log(res.data.data)
        setCategoriesList(res.data.data['categoryMaster'].map(category => category.category_name))
        setTagsList(res.data.data['tagMaster'].map(tag => tag.tag_name))
        setLoading(false)
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    useEffect(() => {
        fetchBlogList()
        // fetchTagsList()
        fetchCategoriesList()
    }, [])


    const handlePrevButton = () => {
        if (currentPage > 1) {
            if (currentPageList[0] !== 1) {
                setCurrentPageList(currentPageList.map(page => page - 1))
            }
            setCurrentPage(currentPage - 1)
            setCurrentBlogList(blogList.slice(10 * (currentPage - 2), 10 * (currentPage - 2) + 10))
        }
    }

    const handleNextButton = () => {
        if (currentPage < blogList.length / 10) {
            if (currentPageList[currentPageList.length - 1] < blogList.length / 10) {
                setCurrentPageList(currentPageList.map(page => page + 1))
            }
            setCurrentPage(currentPage + 1)
            setCurrentBlogList(blogList.slice(10 * (currentPage), 10 * (currentPage) + 10))
        }
    }

    const handlePageButton = (e) => {
        setCurrentPage(parseInt(e.target.name))
        setCurrentBlogList(blogList.slice(10 * (parseInt(e.target.name) - 1), 10 * (parseInt(e.target.name) - 1) + 10))
    }

    const handleCategoryButtonClick = (e) => {
        console.log("category", e.target.innerText);
        setCurrentCategory(e.target.innerText === currentCategory ? '' : e.target.innerText)
    }

    const handleTagsButtonClick = (tag) => {
        if (currentTags.includes(tag)) {
            setCurrentTags(currentTags.filter(item => item !== tag));
        } else {
            setCurrentTags([...currentTags, tag]);
        }
    };

    // Filter blog posts based on selected tags
    useEffect(() => {
        if (currentTags.length === 0 && currentCategory === "") {
            setCurrentBlogList(blogList);
        } else {
            const filteredPosts = blogList.filter(post => {
                const postTags = post.tag.split(',');
                const tagMatch = currentTags.length === 0 || currentTags.some(tag => postTags.includes(tag));
                console.log(tagMatch);
                const categoryMatch = currentCategory === "" || post.category === currentCategory;
                console.log(categoryMatch);
                return tagMatch && categoryMatch;
            });
            setCurrentBlogList(filteredPosts);
            console.log(currentBlogList);
        }
    }, [currentTags, currentCategory]);

    const handleSearchButton = () => {
        if (searchInput) {
            let filteredBlogList = blogList.filter(blog => blog.title.toLowerCase().includes(searchInput.toLowerCase()))
            setCurrentBlogList(filteredBlogList)
            setActiveBlog(false)
        }
    }

    const handleCardClick = (e) => {
        Navigate(`/blogdetail/${currentBlogList[e].url_key}`)
        setId(e)
        console.log(e)
        setActiveBlog(true)
        setCurrentCategory('')
        setCurrentTags([])
    }

    const handleBackButton = () => {
        setActiveBlog(false)
    }


    const handleReadMore = (e) => {
        setReadMoreTags(!readMoretags)
    }
    return (
        <div>
            <SEO title="Healthcare Club | Blogs" description="This is the blog page" name="Healthcare Club" type="list" />
            {/* <!-- Blog Start --> */}
            <div className="container-fluid py-6 px-5">
                <div className="row g-5">
                    {/* <!-- Blog list Start --> */}
                    {
                        !activeBlog ?
                            <div className="col-lg-8">
                                <div className="row g-5">
                                    {/* Cards */}
                                    {
                                        currentBlogList.length > 0 ?
                                            currentBlogList.map((blog, index) =>
                                                <div className="col-xl-6 col-lg-12 col-md-6">
                                                    <Link style={{ cursor: "pointer" }} to={`/blogdetail${blog.url_key}`} className="blog-item">

                                                        {/* image banner */}
                                                        <div className="position-relative overflow-hidden">
                                                            <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}uploads/${blog.image}`} alt="" />
                                                        </div>
                                                        <div className="bg-secondary d-flex">

                                                            {/* Date */}
                                                            <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                                                                <span>{blog.blog_date.split('-')[2].split('T')[0]}</span>
                                                                <h5 className="text-uppercase m-0">{months[parseInt(blog.blog_date.split('-')[1]) - 1]}</h5>
                                                                <span>{blog.blog_date.split('-')[0]}</span>
                                                            </div>

                                                            {/* Content */}
                                                            <div className="d-flex flex-column justify-content-center py-3 px-4">
                                                                <div className="d-flex mb-2">
                                                                    {/* Created By */}
                                                                    <small className="text-uppercase me-3"><i className="bi bi-person me-2"></i>{blog.author_name}</small>

                                                                    {/* Categories */}
                                                                    <small className="text-uppercase me-3"><i className="bi bi-bookmarks me-2"></i>{blog.category}</small>
                                                                </div>

                                                                {/* Title */}
                                                                <div className="h4">{blog.title}</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                            :
                                            <>
                                                <div className="col-12">
                                                    <div className="alert alert-danger text-center">No Blogs Found</div>
                                                </div>
                                            </>
                                    }

                                    {/* Pagination */}

                                    {
                                        !(currentCategory || currentTags.length > 0 || searchInput !== "") &&
                                        <div className="col-12">
                                            <nav aria-label="Page navigation">
                                                <ul className="pagination pagination-lg m-0">
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : null}`}>
                                                        <button className="page-link rounded-0" disabled={currentPage === 1} onClick={handlePrevButton} aria-label="Previous">
                                                            <span aria-hidden="true"><i className="bi bi-arrow-left"></i></span>
                                                        </button>
                                                    </li>
                                                    {
                                                        currentPageList.map(page =>
                                                            <li className={`page-item ${page === currentPage ? 'active' : null}`}><button className="page-link" name={page} onClick={handlePageButton}>{page}</button></li>
                                                        )
                                                    }
                                                    <li className={`page-item ${currentPage < blogList.length / 10 ? null : 'disabled'}`}>
                                                        <button className="page-link rounded-0" onClick={handleNextButton} aria-label="Next">
                                                            <span aria-hidden="true"><i className="bi bi-arrow-right"></i></span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>}
                                </div>
                            </div>
                            :
                            <>
                                <div className="col-lg-8">
                                    <div>
                                        <button className='btn btn-primary mt-2 mb-5 px-4 text-capitalise fw-bold fs-4' onClick={handleBackButton} >Back</button>
                                    </div>
                                    <div className="row g-5">
                                        <DetailBlog id={id} />
                                    </div>
                                </div>
                            </>
                    }
                    {/* <!-- Blog list End --> */}

                    {/* <!-- Sidebar Start --> */}
                    <div className="col-lg-4">
                        {/* <!-- Search Form Start --> */}
                        <div className="mb-5">
                            <div className="input-group">
                                <input value={searchInput} onChange={(e) => setSearchInput(e.target.value)} type="text" className="form-control p-3" placeholder="Keyword" />
                                <button onClick={handleSearchButton} className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                            </div>
                        </div>
                        {/* <!-- Search Form End --> */}

                        {/* <!-- Category Start --> */}
                        <div className="mb-5">
                            <h2 className="mb-4">Categories</h2>
                            <div className="d-flex flex-column justify-content-start bg-secondary p-4">
                                {
                                    categoriesList.map(category =>
                                        <div onClick={handleCategoryButtonClick} className='d-flex w-full h-full py-1 justify-content-between'>
                                            <div className={`h5 d-flex my-auto ${currentCategory === category ? 'text-danger' : null}`} style={{ cursor: "pointer" }} ><i className="bi bi-arrow-right text-primary me-2"></i>{category}</div>
                                            {currentCategory === category && <div className='border text-danger fw-bold border-0 px-1 bg-transparent d-flex pt-0 mt-0 h-full'>X</div>}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {/* <!-- Category End --> */}

                        {/* <!-- Tags Start --> */}
                        <div className="mb-5">
                            <h2 className="mb-4">Tag Cloud</h2>
                            <div className="m-n1">
                                {

                                    tagsList.length > 10 ?

                                        <>

                                            <div className='d-flex flex-wrap '>
                                                {tagsList.slice(0, (readMoretags ? tagsList.length : 10)).map(tag =>
                                                    <div onClick={() => handleTagsButtonClick(tag)} className={`btn ${!currentTags.includes(tag) ? 'btn-secondary' : 'btn-primary'} m-1`}>{tag}</div>
                                                )}
                                            </div>
                                            <div style={{ cursor: "pointer" }} className='text-primary text-center d-flex w-100 mx-auto fw-bold m-1' onClick={handleReadMore}>
                                                read {!readMoretags ? 'more' : 'less'}</div>
                                        </>



                                        :
                                        tagsList.map(tag =>
                                            <div onClick={handleTagsButtonClick} className={`btn ${!currentTags.includes(tag) ? 'btn-secondary' : 'btn-primary'} m-1`}>{tag}</div>
                                        )
                                }
                            </div>
                        </div>
                        {/* <!-- Tags End --> */}

                        {/* <!-- Recent Post Start --> */}
                        <div className="mb-5 d-flex flex-column w-100">
                            <h2 className="mb-4">Recent Post</h2>
                            {
                                blogList.length > 0 &&
                                blogList.slice(0, 3).map(blog =>
                                    <div className="d-flex w-100 mb-3" onClick={() => handleCardClick(blog.id)} style={{ cursor: "pointer" }}>
                                        <img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}uploads/${blog.image}`}style={{ width: "100px", height: "100px", objectFit: "cover" }} alt="" />
                                        <div className="h5 w-100 px-3 d-flex align-items-center bg-secondary px-3 mb-0">{blog.title.length > 30 ? blog.title.slice(0, 20) + '...' : blog.title}
                                        </div>
                                    </div>
                                )}
                        </div>
                        {/* <!-- Recent Post End --> */}
                    </div>
                    {/* <!-- Sidebar End --> */}
                </div>
            </div>
            {/* <!-- Blog End --> */}
            {
                loading && <Loader />}
        </div >
    )
}

export default Blogs