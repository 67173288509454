import { toast } from "react-hot-toast";

import { setLoading, setToken } from "../../slices/authSlice";

export function login(username, password, navigate) {
  console.log('inside authentican');
  console.log(username);
  console.log(password);

  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {

      if (username !== 'admin' && password !== 'MSLTech@54321') {
        throw new Error('Incorrect Username and Password');
      }

      const token = '12345678'

      toast.success("Login Successful");
      localStorage.setItem('token', token);
      dispatch(setToken(token));
      navigate("/admin");
    } catch (error) {
      console.log("LOGIN API ERROR............", error);
      toast.error("Login Failed");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function logout(navigate) {
  return (dispatch) => {
    dispatch(setToken(null));
    localStorage.removeItem('token');
    toast.success("Logged Out");
    navigate("/login");
  };
}
