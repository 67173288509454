import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Logo from "../../assets/images/HVLogo.jpg";
import { Link } from 'react-router-dom';

const CustomNavbar = () => {
    const [activeTab, setActiveTab] = useState("Home");

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <Navbar bg="white" expand="lg" className="shadow-sm px-5 py-3 py-lg-0">
            <Link to="/" className="navbar-brand p-0">
                <img src={Logo} width={200} alt="Logo" />
            </Link>
            <Navbar.Toggle aria-controls="navbarCollapse" />
            <Navbar.Collapse id="navbarCollapse">
                <Nav className="ms-auto py-0 me-n3 ">
                    <Link to="/" className={`nav-item nav-link ${activeTab === "Home" ? "active" : ""}`} onClick={() => handleTabClick("Home")}>Home</Link>
                    <Link to="/about" className={`nav-item nav-link ${activeTab === "About" ? "active" : ""}`} onClick={() => handleTabClick("About")}>About</Link>
                    <Link to="/blog" className={`nav-item nav-link ${activeTab === "Blog" ? "active" : ""}`} onClick={() => handleTabClick("Blog")}>Blogs</Link>
                    <Link to="/contact" className={`nav-item nav-link ${activeTab === "Contact" ? "active" : ""}`} onClick={() => handleTabClick("Contact")}>Contact</Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default CustomNavbar;
