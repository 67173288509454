import React from 'react'
const PrivacyPolicy = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
            <div style={{ width: '100%', textAlign: 'right', marginRight: '100px' }}>
    
                </div>
                <p> <br /></p>
                <h1>Privacy Policy</h1>
                <p><br /> <br />Our Privacy Policy was last updated on October 31, 2023. <br /> <br />This
                    Privacy Policy outlines the policies and procedures for the collection, use, and disclosure of your
                    <br />information when you use the Healthcare Club mobile app (&quot;the App&quot;). It also informs you about
                    your privacy rights <br />and how the law protects your data. By using the App, you agree to the
                    collection and use of information in <br />accordance with this Privacy Policy. <br /></p>
                <p>Interpretation and Definitions <br /></p>
                <p>Interpretation <br /> <br />The words of which the initial letter is capitalized have meanings defined
                    under the following conditions. <br />The following definitions shall have the same meaning regardless
                    of whether they appear in singular or in <br />plural. <br /></p>
                <p>Definitions <br /> <br />For the purposes of this Privacy Policy: <br /> <br /></p>
                <p>&#9679; &quot;<b>Account</b>&quot; means a unique account created for you to access our Service or parts
                    of our Service. <br /> <br /></p>
                <p>&#9679; &quot;<b>Business</b>&quot;, for the purpose of the CCPA (California Consumer Privacy Act),
                    refers to the Company <br />as the legal entity that collects Consumers' personal information and
                    determines the purposes and <br />means of the processing of Consumers' personal information, or on
                    behalf of which such <br />information is collected and that alone, or jointly with others, determines
                    the purposes and means of <br />the processing of consumers' personal information, that does business in
                    the State of California. <br /> <br /></p>
                <p>&#9679; &quot;<b>Company</b>&quot; (referred to as either &quot;the Company&quot;, &quot;We&quot;,
                    &quot;us&quot; or &quot;Our&quot; in this Agreement) refers to H-<br />Vault. <br /> <br />For the
                    purpose of the GDPR, the Company is the Data Controller. <br /> <br /></p>
                <p>&#9679; &quot;<b>Country</b>&quot; refers to INDIA. <br /> <br /></p>
                <p>&#9679; &quot;<b>Consumer</b>&quot;, for the purpose of the CCPA (California Consumer Privacy Act), means
                    a natural <br />person who is a California resident. A resident, as defined in the law, includes (1)
                    every individual <br />who is in the USA for other than a temporary or transitory purpose, and (2) every
                    individual who is <br />domiciled in the USA who is outside the USA for a temporary or transitory
                    purpose. <br /> <br /></p>
                <p>&#9679; &quot;<b>Cookies</b>&quot; are small files that are placed on your computer, mobile device or any
                    other device by a <br />website, containing the details of your browsing history on that website among
                    its many uses. </p>
    
            </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p> <br />&#9679; &quot;<b>Data Controller</b>&quot;, for the purposes of the GDPR (General Data Protection
                    Regulation), refers to the <br /></p>
                <p>Company as the legal person which alone or jointly with others determines the purposes and means <br />of
                    the processing of Personal Data. <br /> <br /></p>
                <p>&#9679; &quot;<b>Device</b>&quot; means any device that can access the Service such as a computer, a cell
                    phone or a <br />digital tablet. <br /> <br /></p>
                <p>&#9679; &quot;<b>Do Not Track&quot; (DNT)&#8221;</b> is a concept that has been promoted by US regulatory
                    authorities, in <br />particular the U.S. Federal Trade Commission (FTC), for the Internet industry to
                    develop and <br />implement a mechanism for allowing internet users to control the tracking of their
                    online activities <br />across websites. <br /> <br /></p>
                <p>&#9679; &quot;<b>Personal Data</b>&quot; is any information that relates to an identified or identifiable
                    individual. <br /> <br />For the purposes of GDPR, Personal Data means any information relating to you
                    such as a name, <br />an identification number, location data, online identifier or to one or more
                    factors specific to the <br />physical, physiological, genetic, mental, economic, cultural or social
                    identity. <br /> <br />For the purposes of the CCPA, Personal Data means any information that
                    identifies, relates to, <br />describes or is capable of being associated with, or could reasonably be
                    linked, directly or indirectly, <br />with you. <br /> <br /></p>
                <p>&#9679; &quot;<b>Sale</b>&quot;, for the purpose of the CCPA (California Consumer Privacy Act), means
                    selling, renting, <br />releasing, disclosing, disseminating, making available, transferring, or
                    otherwise communicating <br />orally, in writing, or by electronic or other means, a Consumer's personal
                    information to another <br />business or a third party for monetary or other valuable consideration.
                    <br /> <br /></p>
                <p>&#9679; &quot;<b>Service</b>&quot; refers to the Application. <br /> <br /></p>
                <p>&#9679; &quot;<b>Service Provider</b>&quot; means any natural or legal person who processes the data on
                    behalf of the <br />Company. It refers to third-party companies or individuals employed by the Company
                    to facilitate the <br />Service, to provide the Service on behalf of the Company, to perform services
                    related to the Service <br />or to assist the Company in analyzing how the Service is used. For the
                    purpose of the GDPR, <br />Service Providers are considered Data Processors. <br /> <br /></p>
                <p>&#9679; &quot;<b>Usage Data</b>&quot; refers to data collected automatically, either generated by the use
                    of the Service or <br />from the Service infrastructure itself (for example, the duration of a page
                    visit). <br /> <br /></p>
                <p>&#9679; &quot;<b>Application</b>&quot; refers to Healthcare Club, accessible from Google Play Store. <br />
                    <br /></p>
                <p>&#9679; &quot;Y<b>ou</b>&quot; means the individual accessing or using the Service, or the company, or
                    other legal entity on <br />behalf of which such individual is accessing or using the Service, as
                    applicable. <br /> <br />Under GDPR (General Data Protection Regulation), you can be referred to as the
                    Data Subject or <br />as the User as you are the individual using the Service. <br /></p>
                <p> </p>
    
            </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p>Collecting and Using your Personal Data <br /></p>
                <p>Types of Data Collected <br /></p>
                <p>Personal Data <br /> <br />While using Healthcare Club, we may ask you to provide us with certain personally
                    identifiable information that can <br />be used to contact or identify you. This information may
                    include: <br /> <br /></p>
                <p>&#9679; Email address <br />&#9679; First name and last name <br />&#9679; Phone number <br />&#9679;
                    Address, State, Province, ZIP/Postal code, City <br />&#9679; Usage Data <br /></p>
                <p>Usage Data <br /> <br />Usage Data is collected automatically when you use the app. This may include:
                    <br /> <br /></p>
                <p>&#8226; your device's Internet Protocol address (e.g., IP address) <br />&#8226; Device type and version
                    <br />&#8226; Pages visited within the app <br />&#8226; Time and date of app usage <br />&#8226; Time
                    spent on app pages <br />&#8226; Unique device identifiers <br />&#8226; Other diagnostic data <br />
                </p>
                <p> <br />When you access the app through a mobile device, we may collect additional information, including
                    the <br />type of mobile device you use, your mobile device's unique ID, your mobile device's IP
                    address, your <br />mobile operating system, and other diagnostic data. <br /> <br />We may also collect
                    information you send whenever you use Healthcare Club. <br />. <br /> <br /></p>
                <p>Use of your Personal Data <br /> <br />Healthcare Club may use your personal data for the following purposes:
                    <br /> <br /></p>
                <p>&#9679; <b>Providing and Maintaining the Service:</b> We use your personal data to provide and maintain
                    our <br />mobile app. This includes monitoring the usage of our app. <br /></p>
                <p> <br />&#61647; <b>Managing your Account:</b> your personal data is used to manage your registration as a
                    user of the <br /></p>
                <p>app. The information you provide grants you access to various functionalities available to registered
                    <br />users. <br /></p>
                <p> <br />&#61647; <b>Performance of a Contract:</b> We process your personal data to develop, comply with,
                    and execute <br /></p>
                <p>the contract for the products, items, or services you've purchased or any other contract with us
                    <br />through the app. <br /></p>
                <p> </p>
    
            </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p>&#61647; <b>Contacting you:</b> We may contact you through email, telephone calls, SMS, or other
                    equivalent <br />electronic communication methods, including push notifications within the app. This
                    contact may <br />involve updates, informative communications related to functionalities, products, or
                    contracted <br />services, as well as security updates when necessary or reasonable for their
                    implementation. <br /></p>
                <p> <br />&#61647; <b>Providing News, Special Offers, and General Information:</b> We use your personal data
                    to provide <br /></p>
                <p>you with news, special offers, and general information about other goods, services, and events that
                    <br />are similar to those you've purchased or inquired about, unless you have opted out of receiving
                    <br />such information. <br /></p>
                <p> <br />&#61647; <b>Managing your Requests:</b> We use your data to attend to and manage your requests to
                    us. <br /></p>
                <p> <br />&#61647; <b>Business Transfers:</b> In the event of a merger, divestiture, restructuring,
                    reorganization, dissolution, <br /></p>
                <p>or other sale or transfer of some or all of our assets, your personal data may be used as part of the
                    <br />assets transferred. This may occur as a going concern or as part of bankruptcy, liquidation, or
                    <br />similar proceedings. <br /></p>
                <p> <br />&#61647; <b>Other Purposes:</b> your data may be used for other purposes, including data analysis,
                    identifying <br /></p>
                <p>usage trends, evaluating the effectiveness of our promotional campaigns, and improving our app,
                    <br />products, services, marketing, and your user experience. <br /></p>
                <p> <br />We may share your personal information in the following situations: <br /> <br /></p>
                <p>&#9679; <b>With Service Providers</b>: We may share your personal information with service providers to
                    monitor <br />and analyze the use of our app, for payment processing, and to contact you. <br /></p>
                <p>&#9679; <b>For business transfers:</b> your personal information may be shared or transferred in
                    connection <br />with, or during negotiations of, any merger, sale of company assets, financing, or
                    acquisition of all or <br />a portion of our business by another company. <br /></p>
                <p>&#9679; <b>With Affiliates:</b> We may share your information with our affiliates, subject to their
                    agreement to <br />adhere to this Privacy Policy. Affiliates include our parent company and any
                    subsidiaries, joint <br />venture partners, or companies under common control with us. <br /></p>
                <p>&#9679; <b>With business partners: </b>We may share your information with our business partners to offer
                    you <br />certain products, services, or promotions. <br /></p>
                <p>&#9679; <b>With other users: </b>When you share personal information or interact in public areas with
                    other users <br />within the app, that information may be viewed by all users and may be publicly
                    distributed outside <br />the app. <br /></p>
                <p>&#9679; <b>With your consent:</b> We may disclose your personal information for any other purpose with
                    your <br />explicit consent. <br /></p>
                <p>Retention of your Personal Data <br /> <br />Healthcare Club will retain your personal data only for as long as
                    it is necessary for the purposes outlined in this <br />Privacy Policy. We will keep and use your
                    personal data as required to comply with our legal obligations, <br />such as retaining your data to
                    adhere to applicable laws, settle disputes, and enforce our legal agreements <br />and policies. <br />
                    <br />We will also retain usage data for internal analysis purposes. Usage data is typically retained
                    for a shorter <br />duration, except when this data is used to enhance security or improve the
                    functionality of our app, or when <br />we are legally obligated to retain this data for longer periods.
                </p>
    
            </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p>Transfer of your Personal Data <br /> <br />your information, including Personal Data, is processed at
                    Healthcare Club's operating offices and other locations <br />where the parties involved in data processing are
                    situated. This means that your information may be <br />transferred to and stored on computers located
                    outside your state, province, country, or other governmental <br />jurisdiction, where data protection
                    laws may differ from those in your jurisdiction. <br /> <br />By consenting to this Privacy Policy and
                    providing such information, you agree to the transfer of your data. <br />Healthcare Club will take all
                    reasonable steps to ensure that your data is handled securely and in accordance with <br />this Privacy
                    Policy. We will not transfer your Personal Data to any organization or country without
                    <br />implementing adequate controls to safeguard your data and other personal information. <br /></p>
                <p>Disclosure of your Personal Data <br /></p>
                <p>Business Transactions <br /> <br />In the event that Healthcare Club is involved in a merger, acquisition, or
                    asset sale, your Personal Data may be <br />transferred. We will provide you with notice before your
                    Personal Data is transferred and becomes subject <br />to a different Privacy Policy. <br /></p>
                <p>Law enforcement <br /> <br />Under certain circumstances, Healthcare Club may be required to disclose your
                    Personal Data if mandated by law <br />or in response to valid requests from public authorities, such as
                    a court or government agency. <br /> <br /></p>
                <p>Other legal requirements <br /> <br />Healthcare Club may disclose your Personal Data in good faith when such
                    action is deemed necessary to: <br /> <br /></p>
                <p>&#9679; Comply with a Legal Obligation. <br />&#61647; Protect and Defend the Rights or Property of
                    Healthcare Club. <br />&#61647; Prevent or Investigate Possible Wrongdoing in Connection with the Service.
                    <br />&#61647; Protect the Personal Safety of Users of the Service or the Public. <br />&#61647; Protect
                    Against Legal Liability. <br /></p>
                <p> <br /></p>
                <p>Security of your Personal Data <br /> <br />The security of your Personal Data is a priority for us. It's
                    important to note that while we make every effort <br />to use commercially acceptable methods to
                    protect your Personal Data, no method of transmission over the <br />Internet or electronic storage can
                    be guaranteed to be 100% secure. <br /> <br />We will continue to work diligently to safeguard your
                    information, but we cannot ensure its absolute <br />security. </p>
    
            </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p>GDPR Privacy Policy <br /></p>
                <p>Legal Basis for Processing Personal Data under GDPR <br /> <br />We may process Personal Data under the
                    following conditions: <br /> <br /></p>
                <p>&#9679; <b>Consent</b>: you have given your consent for processing Personal Data for one or more specific
                    <br />purposes <br /></p>
                <p>&#9679; <b>Performance of a contract</b>: Provision of Personal Data is necessary for the performance of
                    an <br />agreement with you and/or for any pre-contractual obligations thereof. <br /></p>
                <p>&#9679; <b>Legal obligations</b>: Processing Personal Data is necessary for compliance with a legal
                    obligation to <br />which the Company is subject. <br /></p>
                <p>&#9679; <b>Vital interests</b>: Processing Personal Data is necessary in order to protect your vital
                    interests or of <br />another natural person. <br /></p>
                <p>&#9679; <b>Public interests</b>: Processing Personal Data is related to a task that is carried out in the
                    public <br />interest or in the exercise of official authority vested in the Company. <br /></p>
                <p>&#9679; <b>Legitimate interests</b>: Processing Personal Data is necessary for the purposes of the
                    legitimate <br />interests pursued by the Company. <br /></p>
                <p> <br />In any case, the Company will gladly help to clarify the specific legal basis that applies to the
                    processing, <br />and in particular whether the provision of Personal Data is a statutory or contractual
                    requirement, or a <br />requirement necessary to enter into a contract. <br /></p>
                <p>Your Rights under the GDPR <br /> <br />The Company undertakes to respect the confidentiality of your
                    Personal Data and to guarantee you can <br />exercise your rights. <br /> <br />you have the right under
                    this Privacy Policy, and by law if you are within the EU, to: <br /> <br /></p>
                <p>&#9679; <b>Request access to your Personal Data</b>. The right to access, update or delete the
                    information We <br />have on you. Whenever made possible, you can access, update or request deletion of
                    your <br />Personal Data directly within your account settings section. If you are unable to perform
                    these <br />actions yourself, please contact us to assist you. This also enables you to receive a copy
                    of the <br />Personal Data We hold about you. <br /></p>
                <p>&#9679; <b>Request correction of the Personal Data that We hold about you</b>. you have the right to have
                    <br />any incomplete or inaccurate information We hold about you corrected. <br /></p>
                <p>&#9679; <b>Object to processing of your Personal Data</b>. This right exists where We are relying on a
                    <br />legitimate interest as the legal basis for Our processing and there is something about your
                    particular <br />situation, which makes you want to object to our processing of your Personal Data on
                    this ground. <br />you also have the right to object where We are processing your Personal Data for
                    direct marketing <br />purposes. <br /></p>
                <p>&#9679; <b>Request erasure of your Personal Data</b>. you have the right to ask us to delete or remove
                    <br />Personal Data when there is no good reason for us to continue processing it. <br /></p>
                <p>&#9679; <b>Request the transfer of your Personal Data</b>. We will provide to you, or to a third-party
                    you have <br />chosen, your Personal Data in a structured, commonly used, machine-readable format.
                    Please note <br />that this right only applies to automated information which you initially provided
                    consent for us to <br />use or where We used the information to perform a contract with you. <br /></p>
                <p>&#9679; <b>Withdraw your consent.</b> you have the right to withdraw your consent on using your Personal
                    Data. <br />If you withdraw your consent, We may not be able to provide you with access to certain
                    specific <br />functionalities of the Service. </p>
                <p>&#9679; <b>Actions Against Misuse or Illegal Activities.</b> <br />
                    We reserve the right to take appropriate actions against any requestor if we become aware of any illegal <br /> activities or misuse of information provided through our platform. Our commitment to maintaining a <br /> secure and trustworthy environment empowers us to investigate, report, and take necessary legal actions <br /> to address any activities that contravene the law or our terms of service. This may include, but is not <br /> limited to, suspending or terminating the user's access to our services and cooperating with law <br /> enforcement authorities in accordance with applicable legal requirements. It is important to note that <br /> Healthcare Club is not responsible if a donor decides to take any actions against you based on their discretion <br /> and interpretation of the situation.
                 </p>
                <p>&#9679; <b>Sharing of Location and Contact Information with Requestor.</b> <br />
                    By expressing interest in a blood donation request through our platform, you agree to our disclaimer <br /> and consent to the sharing of your distance location and mobile phone number with the requestor. <br /> This information is shared solely for the purpose of facilitating the connection between donors and <br /> requestors, enhancing the efficiency of the donation process. We take your privacy seriously and <br /> implement stringent security measures to protect your personal information. However, it is your <br /> responsibility to ensure that you are comfortable with sharing this information under the terms outlined <br /> in this policy.
                 </p>
    
            </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p>Exercising of your GDPR Data Protection Rights <br /> <br />you may exercise your rights of access,
                    rectification, cancellation and opposition by contacting us. Please <br />note that we may ask you to
                    verify your identity before responding to such requests. If you make a request, <br />We will try our
                    best to respond to you as soon as possible. <br /> <br />you have the right to complain to a Data
                    Protection Authority about Our collection and use of your Personal <br />Data. For more information, if
                    you are in the European Economic Area (EEA), please contact your local <br />data protection authority
                    in the EEA. <br /></p>
                <p>CCPA Privacy Policy <br /> <br />This privacy notice section for California residents supplements the
                    information contained in Our Privacy <br />Policy and it applies solely to all visitors, users, and
                    others who reside in the State of California. <br /></p>
                <p>Categories of Personal Information Collected <br /> <br />We collect information that identifies, relates
                    to, describes, references, is capable of being associated with, <br />or could reasonably be linked,
                    directly or indirectly, with a particular Consumer or Device. The following is a <br />list of
                    categories of personal information which we may collect or may have been collected from California
                    <br />residents within the last twelve (12) months. <br /> <br />Please note that the categories and
                    examples provided in the list below are those defined in the CCPA. <br />This does not mean that all
                    examples of that category of personal information were in fact collected by us, <br />but reflects our
                    good faith belief to the best of our knowledge that some of that information from the <br />applicable
                    category may be and may have been collected. For example, certain categories of personal
                    <br />information would only be collected if you provided such personal information directly to us.
                    <br /> <br /></p>
                <p>&#9679; <b>Category A: Identifiers. <br /> <br /></b>Examples: A real name, alias, postal address, unique
                    personal identifier, online identifier, Internet <br />Protocol address, email address, account name,
                    driver's license number, passport number, or other <br />similar identifiers. <br /> <br />Collected:
                    Yes.<b> <br /></b> <br /></p>
                <p>&#9679; <b>Category B: Personal information categories listed in the California Customer Records
                        <br />statute (Cal. Civ. Code &#167; 1798.80(e)). <br /> <br /></b>Examples: A name, signature,
                    Social Security number, physical characteristics or description, <br />address, telephone number,
                    passport number, driver's license or state identification card number, <br />insurance policy number,
                    education, employment, employment history, bank account number, credit <br />card number, debit card
                    number, or any other financial information, medical information, or health <br />insurance information.
                    Some personal information included in this category may overlap with other <br />categories. <br />
                    <br />Collected: Yes.<b> <br /></b> <br /></p>
                <p>&#9679; <b>Category C: Protected classification characteristics under California or federal law. <br />
                    </b></p>
    
            </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p>Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or
                    <br />creed, marital status, medical condition, physical or mental disability, sex (including gender,
                    gender <br />identity, gender expression, pregnancy or childbirth and related medical conditions),
                    sexual <br />orientation, veteran or military status, genetic information (including familial genetic
                    information). <br /> <br />Collected: No.<b> <br /></b> <br /></p>
                <p>&#9679; <b>Category D: Commercial information. <br /> <br /></b>Examples: Records and history of products
                    or services purchased or considered. <br /> <br />Collected: Yes.<b> <br /></b> <br /></p>
                <p>&#9679; <b>Category E: Biometric information. <br /> <br /></b>Examples: Genetic, physiological,
                    behavioral, and biological characteristics, or activity patterns used <br />to extract a template or
                    other identifier or identifying information, such as, fingerprints, faceprints, <br />and voiceprints,
                    iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or <br />exercise
                    data. <br /> <br />Collected: No.<b> <br /></b></p>
                <p> <br />&#9679; <b>Category F: Internet or other similar network activity. <br /></b></p>
                <p><b> <br /></b>Examples: Interaction with our Service or advertisement. <br /> <br />Collected: Yes.<b>
                        <br /></b> <br /></p>
                <p>&#9679; <b>Category G: Geolocation data. <br /> <br /></b>Examples: Approximate physical location. <br />
                    <br />Collected: Yes.<b> <br /></b> <br /></p>
                <p>&#9679; <b>Category H: Sensory data. <br /> <br /></b>Examples: Audio, electronic, visual, thermal,
                    olfactory, or similar information. <br /> <br />Collected: No.<b> <br /></b> <br /></p>
                <p>&#9679; <b>Category I: Professional or employment-related information. <br /> <br /></b>Examples: Current
                    or past job history or performance evaluations. <br /> <br />Collected: No.<b> <br /></b> <br /></p>
                <p>&#9679; <b>Category J: Non-public education information (per the Family Educational Rights and
                        <br />Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)). <br /> <br /></b>Examples:
                    Education records directly related to a student maintained by an educational institution or <br />party
                    acting on its behalf, such as grades, transcripts, class lists, student schedules, student
                    <br />identification codes, student financial information, or student disciplinary records. </p>
    
            </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p> <br />Collected: No.<b> <br /></b> <br /></p>
                <p>&#9679; <b>Category K: Inferences drawn from other personal information. <br /> <br /></b>Examples:
                    Profile reflecting a person's preferences, characteristics, psychological trends, <br />predispositions,
                    behavior, attitudes, intelligence, abilities, and aptitudes. <br /> <br />Collected: No.<b> <br /></b>
                </p>
                <p> <br />Under CCPA, personal information does not include: <br /> <br /></p>
                <p>&#9679; Publicly available information from government records <br />&#9679; Deidentified or aggregated
                    consumer information <br />&#9679; Information excluded from the CCPA's scope, such as: <br />&#9679;
                    Health or medical information covered by the Health Insurance Portability and Accountability Act of
                    <br /></p>
                <p>1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial
                    <br />data <br /></p>
                <p>&#9679; Personal Information covered by certain sector-specific privacy laws, including the Fair Credit
                    <br />Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information
                    <br />Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994 <br /></p>
                <p>Sources of Personal Information <br /> <br />We obtain the categories of personal information listed
                    above from the following categories of sources: <br /> <br /></p>
                <p>&#9679; Directly from you. For example, from the forms you complete on our app, preferences you express
                    <br />or provide through our Service, or from your purchases on our app. <br /></p>
                <p>&#9679; Indirectly from you. For example, from observing your activity on our app. <br />&#9679;
                    Automatically from you. For example, through cookies we or our service providers set on your <br /></p>
                <p>device as you navigate through our app. <br />&#9679; From Service Providers. For example, third-party
                    vendors to monitor and analyze the use of our <br /></p>
                <p>Service, third-party vendors for payment processing, or other third-party vendors that We use to
                    <br />provide the Service to you. <br /></p>
                <p>Use of Personal Information for Business Purposes or Commercial Purposes <br /> <br />We may use or
                    disclose personal information We collect for &quot;business purposes&quot; or &quot;commercial
                    purposes&quot; <br />(as defined under the CCPA), which may include the following examples: <br />
                    <br /></p>
                <p>&#9679; To operate our Service and provide you with our Service. <br />&#9679; To provide you with
                    support and to respond to your inquiries, including to investigate and address <br /></p>
                <p>your concerns and monitor and improve our Service. <br />&#9679; To fulfill or meet the reason you
                    provided the information. For example, if you share your contact <br /></p>
                <p>information to ask a question about our Service, We will use that personal information to respond to
                    <br />your inquiry. If you provide your personal information to purchase a product or service, We will
                    use <br />that information to process your payment and facilitate delivery. <br /></p>
                <p>&#9679; To respond to law enforcement requests and as required by applicable law, court order, or
                    <br />governmental regulations. <br /></p>
                <p>&#9679; As described to you when collecting your personal information or as otherwise set forth in the
                    <br />CCPA. </p>
    
            </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p>&#9679; For internal administrative and auditing purposes. <br />&#9679; To detect security incidents and
                    protect against malicious, deceptive, fraudulent or illegal activity, <br /></p>
                <p>including, when necessary, to prosecute those responsible for such activities. <br /> <br />Please note
                    that the examples provided above are illustrative and not intended to be exhaustive. For more
                    <br />details on how we use this information, please refer to the &quot;Use of your Personal Data&quot;
                    section. <br /> <br />If We decide to collect additional categories of personal information or use the
                    personal information We <br />collected for materially different, unrelated, or incompatible purposes We
                    will update this Privacy Policy. <br /></p>
                <p>Disclosure of Personal Information for Business Purposes or Commercial Purposes <br /> <br />We may use
                    or disclose and may have used or disclosed in the last twelve (12) months the following <br />categories
                    of personal information for business or commercial purposes: <br /> <br /></p>
                <p>&#9679; Category A: Identifiers <br />&#9679; Category B: Personal information categories listed in the
                    California Customer Records statute (Cal. <br /></p>
                <p>Civ. Code &#167; 1798.80(e)) <br />&#9679; Category D: Commercial information <br />&#9679; Category F:
                    Internet or other similar network activity <br /></p>
                <p> <br />Please note that the categories listed above are those defined in the CCPA. This does not mean
                    that all <br />examples of that category of personal information were in fact disclosed, but reflects
                    our good faith belief to <br />the best of our knowledge that some of that information from the
                    applicable category may be and may have <br />been disclosed. <br /> <br />When We disclose personal
                    information for a business purpose or a commercial purpose, We enter a <br />contract that describes the
                    purpose and requires the recipient to both keep that personal information <br />confidential and not use
                    it for any purpose except performing the contract. <br /> <br />As defined in the California Consumer
                    Privacy Act (CCPA), &quot;sell&quot; and &quot;sale&quot; encompass various activities, <br />including
                    selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise
                    <br />communicating personal information to a third party for valuable consideration. This valuable
                    consideration <br />may not always be monetary but can take various forms. <br /> <br />Please note that
                    the categories listed below align with those defined in the CCPA. This does not imply that <br />all
                    examples of personal information within each category were sold, but rather reflects our good faith
                    <br />belief, to the best of our knowledge, that some information from these categories may have been
                    shared in <br />exchange for value. <br /> <br />We may have sold and may sell in the last twelve (12)
                    months the following categories of personal <br />information: <br /> <br /></p>
                <p>&#61647; Category A: Identifiers <br />&#61647; Category B: Personal information categories listed in the
                    California Customer Records statute (Cal. <br /></p>
                <p>Civ. Code &#167; 1798.80(e)) <br />&#61647; Category D: Commercial information <br />&#61647; Category F:
                    Internet or other similar network activity <br /></p>
                <p>Share of Personal Information <br /> </p>
    
            </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p>We may share your personal information identified in the above categories with the following categories
                    of <br />third parties: <br /> <br /></p>
                <p>&#9679; Service Providers <br />&#9679; Payment processors <br />&#9679; Our affiliates <br />&#9679; Our
                    business partners <br />&#9679; Third party vendors to whom you or your agents authorize us to disclose
                    your personal information <br /></p>
                <p>in connection with products or services We provide to you <br /></p>
                <p>Sale of Personal Information of Minors Under 16 Years of Age <br /> <br />We do not knowingly collect
                    personal information from minors under the age of 16 through our Service, <br />although certain
                    third-party websites that we link to may do so. These third-party websites have their own <br />terms of
                    use and privacy policies, and we encourage parents and legal guardians to monitor their children's
                    <br />Internet usage and instruct their children to never provide information on other websites without
                    their <br />permission. <br /> <br />We do not sell the personal information of consumers we actually
                    know are less than 16 years of age <br />unless we receive affirmative authorization (the &quot;right to
                    opt-in&quot;) from either the consumer who is between <br />13 and 16 years of age or the parent or
                    guardian of a consumer less than 13 years of age. Consumers who <br />opt-in to the sale of personal
                    information may opt-out of future sales at any time. To exercise the right to <br />opt-out, you (or
                    your authorized representative) may submit a request to us by contacting us. <br /> <br />If you have
                    reason to believe that a child under the age of 13 (or 16) has provided us with personal
                    <br />information, please contact us with sufficient detail to enable us to delete that information.
                    <br /></p>
                <p>Your Rights under the CCPA <br /> <br />The CCPA provides California residents with specific rights
                    regarding their personal information. If you are <br />a resident of California, you have the following
                    rights: <br /> <br /></p>
                <p>&#9679; <b>The right to notice</b>. you have the right to be notified which categories of Personal Data
                    are being <br />collected and the purposes for which the Personal Data is being used. <br /></p>
                <p>&#9679; <b>The right to request.</b> Under CCPA, you have the right to request that We disclose
                    information to <br />you about Our collection, use, sale, disclosure for business purposes and share of
                    personal <br />information. Once We receive and confirm your request, We will disclose to you: <br />
                </p>
                <p>&#9675; The categories of personal information We collected about you <br />&#9675; The categories of
                    sources for the personal information We collected about you <br />&#9675; Our business or commercial
                    purpose for collecting or selling that personal information <br />&#9675; The categories of third
                    parties with whom We share that personal information <br />&#9675; The specific pieces of personal
                    information We collected about you <br />&#9675; If we sold your personal information or disclosed your
                    personal information for a business <br /></p>
                <p>purpose, We will disclose to you: <br />&#9632; The categories of personal information categories sold
                    <br />&#9632; The categories of personal information categories disclosed <br /></p>
                <p>&#9679; <b>The right to say no to the sale of Personal Data (opt-out).</b> you have the right to direct
                    us to not <br />sell your personal information. To submit an opt-out request please contact us. <br />
                </p>
                <p>&#9679; <b>The right to delete Personal Data.</b> you have the right to request the deletion of your
                    Personal <br />Data, subject to certain exceptions. Once We receive and confirm your request, We will
                    delete (and <br />direct Our Service Providers to delete) your personal information from our records,
                    unless an </p>
    
            </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p>exception applies. We may deny your deletion request if retaining the information is necessary for
                    <br />us or Our Service Providers to: <br /></p>
                <p>&#9675; Complete the transaction for which We collected the personal information, provide a good or
                    <br />service that you requested, take actions reasonably anticipated within the context of our
                    <br />ongoing business relationship with you, or otherwise perform our contract with you. <br /></p>
                <p>&#9675; Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity,
                    <br />or prosecute those responsible for such activities. <br /></p>
                <p>&#9675; Debug products to identify and repair errors that impair existing intended functionality.
                    <br />&#9675; Exercise free speech, ensure the right of another consumer to exercise their free speech
                    <br /></p>
                <p>rights, or exercise another right provided for by law. <br />&#9675; Comply with the California
                    Electronic Communications Privacy Act (Cal. Penal Code &#167; 1546 <br /></p>
                <p>et. seq.). <br />&#9675; Engage in public or peer-reviewed scientific, historical, or statistical
                    research in the public <br /></p>
                <p>interest that adheres to all other applicable ethics and privacy laws, when the information's
                    <br />deletion may likely render impossible or seriously impair the research's achievement, if you
                    <br />previously provided informed consent. <br /></p>
                <p>&#9675; Enable solely internal uses that are reasonably aligned with consumer expectations based <br />on
                    your relationship with us. <br /></p>
                <p>&#9675; Comply with a legal obligation. <br />&#9675; Make other internal and lawful uses of that
                    information that are compatible with the context <br /></p>
                <p>in which you provided it. <br />&#9679; <b>The right not to be discriminated against.</b> you have the
                    right not to be discriminated against for <br /></p>
                <p>exercising any of your consumer's rights, including by: <br />&#9675; Denying goods or services to you
                    <br />&#9675; Charging different prices or rates for goods or services, including the use of discounts
                    or <br /></p>
                <p>other benefits or imposing penalties <br />&#9675; Providing a different level or quality of goods or
                    services to you <br />&#9675; Suggesting that you will receive a different price or rate for goods or
                    services or a different <br /></p>
                <p>level or quality of goods or services <br /></p>
                <p>Exercising your CCPA Data Protection Rights <br /> <br />In order to exercise any of your rights under
                    the CCPA, and if you are a California resident, you can contact <br />us: <br /> <br /></p>
                <p>&#9679; By visiting this page on our website: https://Healthcare Club.in <br />&#9679; By sending us an email:
                    info@Healthcare Club.in <br /></p>
                <p> <br />Only you, or a person registered with the California Secretary of State that you authorize to act
                    on your <br />behalf, may make a verifiable request related to your personal information. <br />
                    <br />your request to us must: <br /> <br /></p>
                <p>&#9679; Provide sufficient information that allows us to reasonably verify you are the person about whom
                    We <br />collected personal information or an authorized representative <br /></p>
                <p>&#9679; Describe your request with sufficient detail that allows us to properly understand, evaluate, and
                    <br />respond to it <br /></p>
                <p> <br />We cannot respond to your request or provide you with the required information if we cannot:
                    <br /> <br /></p>
                <p>&#9679; Verify your identity or authority to make the request <br />&#9679; And confirm that the personal
                    information relates to you </p>
    
            </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p>&#9679; We will disclose and deliver the required information free of charge within 45 days of receiving
                    your <br />verifiable request. The time period to provide the required information may be extended once
                    by an <br />additional 45 days when reasonably necessary and with prior notice. <br /></p>
                <p> <br />Any disclosures We provide will only cover the 12-month period preceding the verifiable request's
                    receipt. <br /> <br />For data portability requests, We will select a format to provide your personal
                    information that is readily <br />usable and should allow you to transmit the information from one
                    entity to another entity without hindrance. <br /> <br /></p>
                <p>Do Not Sell My Personal Information <br /> <br />You have the right to opt-out of the sale of your
                    personal information. Once we receive and confirm a <br />verifiable consumer request from you, we will
                    stop selling your personal information. To exercise your right <br />to opt-out, please contact us.
                    <br /> <br />The service providers we partner with (for example, our analytics or advertising partners)
                    may use <br />technology on the App that may be considered as selling personal information under CCPA
                    law. If you wish <br />to opt out of the use of your personal information for interest-based advertising
                    purposes and these <br />potential sales as defined under CCPA law, you may do so by following the
                    instructions below. <br /> <br />Please note that any opt-out is specific to the device and browser you
                    use, and you may need to opt out on <br />each device and browser you use. <br /> <br /></p>
                <p>Website <br /> <br />You can opt out of receiving personalized ads served by our Service Providers by
                    following the instructions <br />presented on the App: <br /> <br /></p>
                <p>&#9679; The NAI's opt-out platform: http://www.networkadvertising.org/choices/ <br />&#9679; The EDAA's
                    opt-out platform http://www.youronlinechoices.com/ <br />&#9679; The DAA's opt-out platform:
                    http://optout.aboutads.info/?c=2&amp;lang=EN <br /></p>
                <p> <br />The opt out will place a cookie on your computer that is unique to the browser you use to opt out.
                    If you <br />change browsers or delete the cookies saved by your browser, you will need to opt out
                    again. <br /></p>
                <p>Mobile Devices <br /> <br />Your mobile device may give you the ability to opt out of the use of
                    information about the apps you use to <br />serve you ads targeted to your interests: <br /></p>
                <p>&#61647; On Android devices, you can select &quot;Opt out of Interest-Based Ads&quot; or &quot;Opt out of
                    Ads <br />Personalization.&quot; <br /></p>
                <p>&#61647; On iOS devices, you can enable &quot;Limit Ad Tracking.&quot; <br /> <br />You can also stop the
                    collection of location information from your mobile device by changing the <br />preferences on your
                    mobile device. </p>
    
            </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p>&quot;Do Not Track&quot; Policy as Required by California Online Privacy Protection <br />Act (CalOPPA)
                    <br /> <br />Our App does not respond to Do Not Track signals. <br /> <br />However, please note that
                    some third-party websites may track your browsing activities. If you visit such <br />websites, you can
                    set your preferences in your web browser to inform websites that you do not want to be <br />tracked.
                    You can enable or disable &quot;Do Not Track&quot; (DNT) by visiting the preferences or settings page of
                    <br />your web browser. <br /></p>
                <p>Children's Privacy <br /> <br />Our App does not address anyone under the age of 13. We do not knowingly
                    collect personally identifiable <br />information from anyone under the age of 13. If you are a parent
                    or guardian and you are aware that your <br />child has provided us with Personal Data, please contact
                    us. If We become aware that We have collected <br />Personal Data from anyone under the age of 13
                    without verification of parental consent, We take steps to <br />remove that information from Our
                    servers. <br /> <br />If We need to rely on consent as a legal basis for processing your information and
                    your country requires <br />consent from a parent, We may require your parent's consent before We
                    collect and use that information. <br /></p>
                <p>Your California Privacy Rights (California's Shine the Light law) <br /> <br />Under California Civil
                    Code Section 1798 (California's Shine the Light law), California residents with an <br />established
                    business relationship with us can request information once a year about sharing their Personal
                    <br />Data with third parties for the third parties' direct marketing purposes. <br /> <br />If you'd
                    like to request more information under the California Shine the Light law, and if you are a California
                    <br />resident, you can contact us using the contact information provided below. <br /></p>
                <p>California Privacy Rights for Minor Users (California Business and <br />Professions Code Section 22581)
                    <br /> <br />California Business and Professions Code Section 22581 allows California residents under
                    the age of 18 <br />who are registered users of online sites, services or applications to request and
                    obtain removal of content <br />or information they have publicly posted. <br /> <br />To request
                    removal of such data, and if you are a California resident, you can contact us using the contact
                    <br />information provided below, and include the email address associated with your account. <br />
                    <br />Be aware that your request does not guarantee complete or comprehensive removal of content or
                    <br />information posted online and that the law may not permit or require removal in certain
                    circumstances. <br /></p>
                <p>Links to Other Websites <br /> </p>
    
            </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
            <div>
                <p>Our Service may contain links to other websites that are not operated by us. If you click on a third
                    party link, <br />you will be directed to that third party's site. We strongly advise you to review the
                    Privacy Policy of every <br />site you visit. <br /> <br />We have no control over and assume no
                    responsibility for the content, privacy policies or practices of any <br />third party sites or
                    services. <br /></p>
                <p>Changes to this Privacy Policy <br /> <br />We may update Our Privacy Policy from time to time. We will
                    notify you of any changes by posting the new <br />Privacy Policy on this page. <br /> <br />We will let
                    you know via email and/or a prominent notice on Our Service, prior to the change becoming
                    <br />effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy. <br />
                    <br />you are advised to review this Privacy Policy periodically for any changes. Changes to this
                    Privacy Policy <br />are effective when they are posted on this page. <br /></p>
                <p>Contact us <br /> <br />If you have any questions about this Privacy Policy, you can contact us: <br />
                    <br /></p>
                <p>&#9679; By visiting this page on our website: https://healthcareclub.in <br />&#9679; By sending us an email:
                   <a href='mailto:info@healthcareclub.in'></a> info@healthcareclub.in <br /></p>
                <p> </p>
    
            </div>
        </div>
        </div>
    </div>
    )
}

export default PrivacyPolicy;