import React from 'react'

const PageTop = () => {
    return (
        <div>
            {/* !-- Back to Top --> */}
            <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i className="bi bi-arrow-up"></i></a>
        </div>
    )
}

export default PageTop