import React from 'react'
import { Link } from 'react-router-dom'

const TopBar = () => {
  return (
    <div className="container-fluid bg-secondary ps-5 pe-0 d-none d-lg-block">
        <div className="row gx-0">
            <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
                <div className="d-inline-flex align-items-center">
                    <Link className="text-body py-2 pe-3 border-end" to="/FAQs"><small>FAQs</small></Link>
                    {/* <Link className="text-body py-2 px-3 border-end" to="#"><small>Support</small></Link> */}
                    <Link className="text-body py-2 px-3 border-end" to="/privacy-policy"><small>Privacy Policy</small></Link>
                    <Link className="text-body py-2 px-3 border-end" to="/terms-and-conditions"><small>Terms and Conditions</small></Link>
                </div>
            </div>
            <div className="col-md-6 text-center text-lg-end">
                <div className="position-relative d-inline-flex align-items-center bg-primary text-white top-shape px-5">
                    <div className="me-3 pe-3 border-end py-2">
                        <a href="mailto:info@healthcareclub.in" className='text-white'>
                            <p className="m-0">
                                <i className="fa fa-envelope-open me-2"></i>
                                info@healthcareclub.in
                            </p>
                        </a>
                    </div>
                    <div className="py-2">
                        <p className="m-0"><i className="fa fa-phone-alt me-2"></i>011 44756172</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TopBar